// logger.js
import { auth } from '../utils/firebaseInit';

const LOG_LEVELS = {
  ERROR: 'ERROR',
  WARN: 'WARN',
  INFO: 'INFO',
  DEBUG: 'DEBUG'
};


// Only log in production when explicitly enabled
const shouldLog = () => {
  return process.env.NODE_ENV !== 'production' || localStorage.getItem('debug_enabled') === 'true';
};
const getEnvironmentInfo = () => ({
  env: process.env.REACT_APP_ENV || 'unknown',
  node_env: process.env.NODE_ENV || 'unknown',
  url: window.location.href,
  timestamp: new Date().toISOString()
});

const logger = {
  error: (message, error = null, context = {}) => {
    if (!shouldLog()) return;
    
    const logData = {
      level: LOG_LEVELS.ERROR,
      message,
      environment: getEnvironmentInfo(),
      error: error ? {
        name: error.name,
        message: error.message,
        stack: error.stack,
        code: error.code, // Firebase often includes error codes
        serverCode: error?.customData?.serverCode
      } : null,
      context: {
        ...context,
        userId: auth?.currentUser?.uid || 'no_user'
      }
    };
    
    console.error('🔴 Error:', logData);
    
    // In production, you might want to send this to a logging service
    if (process.env.NODE_ENV === 'production') {
      // TODO: Send to logging service if needed
    }
  },

  info: (message, data = {}) => {
    if (!shouldLog()) return;
    
    const logData = {
      level: LOG_LEVELS.INFO,
      message,
      environment: getEnvironmentInfo(),
      data,
      context: {
        userId: auth?.currentUser?.uid || 'no_user'
      }
    };
    
    console.log('ℹ️ Info:', logData);
  },

  debug: (message, data = {}) => {
    if (!shouldLog()) return;
    
    const logData = {
      level: LOG_LEVELS.DEBUG,
      message,
      environment: getEnvironmentInfo(),
      data,
      context: {
        userId: auth?.currentUser?.uid || 'no_user'
      }
    };
    
    console.debug('🔍 Debug:', logData);
  },

  warn: (message, data = {}) => {
    if (!shouldLog()) return;
    
    const logData = {
      level: LOG_LEVELS.WARN,
      message,
      environment: getEnvironmentInfo(),
      data,
      context: {
        userId: auth?.currentUser?.uid || 'no_user'
      }
    };
    
    console.warn('⚠️ Warning:', logData);
  },

  // Helper to enable debugging in production
  // enableDebugging: () => {
  //   localStorage.setItem('debug_enabled', 'true');
  //   console.log('Debugging enabled');
  // },

  // Helper to disable debugging
  disableDebugging: () => {
    localStorage.removeItem('debug_enabled');
    console.log('Debugging disabled');
  }
};

// Make logger available globally in production
if (process.env.NODE_ENV === 'production') {
  window.logger = logger;
}

export { logger };

// Performance monitoring utility
export const measurePerformance = async (operationName, operation) => {
  const start = performance.now();
  try {
    const result = await operation();
    const duration = performance.now() - start;
    logger.debug(`Operation ${operationName} completed`, { duration });
    return result;
  } catch (error) {
    const duration = performance.now() - start;
    logger.error(`Operation ${operationName} failed`, error, { duration });
    throw error;
  }
};
import React, { useState, useContext } from 'react';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
import { AppContext } from '../contexts/AppContext.jsx';
import fonts from '../utils/fonts.js';

const FontSelector = () => {
  const { displayedFont, setDisplayedFont, setIsFocused, clickedFont } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFontChange = (font) => {
    setDisplayedFont(font);
    clickedFont.current = font;
    setIsFocused(true);
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            borderRadius: 2
          } 
        }}
      >
        {displayedFont}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {fonts.map((font, index) => (
          <MenuItem key={index} onClick={() => handleFontChange(font.font)}>
            <Typography style={{ fontFamily: font.font }}>
              {font.font}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default FontSelector;
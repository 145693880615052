import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import MascotIcon from './MascotIcon';

const HomeButtonWithLogo = ({ onClick }) => (
  <Button onClick={onClick} color="inherit" sx={{ textAlign: 'left', p: 0 }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <MascotIcon />
      <Box sx={{ ml: 1 }}>
        <Typography variant="h4" component="div" sx={{ lineHeight: 1 }}>
          Typecelerate
        </Typography>
        <Typography 
          variant="subtitle2" 
          sx={{ 
            fontSize: '0.9rem', 
            fontStyle: 'italic',
            color: 'text.secondary',
            lineHeight: 1,
            mt: 0.5
          }}
        >
          The missing piece in your typing practice routine
        </Typography>
      </Box>
    </Box>
  </Button>
);

export { HomeButtonWithLogo };
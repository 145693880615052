import React, { useContext } from 'react';
import  ScrollingDisplay from './ScrollingDisplay';
import  SlidingDisplay from './SlidingDisplay';
import  StaticDisplay from './StaticDisplay';
import { Caret } from '../../Caret';
// import { MainContentContext } from '../../../contexts/MainContentContext';

export const DisplayMode = {
 STATIC: 'static', 
 SCROLLING: 'scrolling',
 SLIDING: 'sliding'
};


const TypingDisplay = ({ displayMode, children, currentIndex, caretProps }) => {
  switch(displayMode) {
    case DisplayMode.SCROLLING:
      return (
        <ScrollingDisplay>
          {children}
          <Caret {...caretProps} />
        </ScrollingDisplay>
      );
    case DisplayMode.SLIDING:
      return <SlidingDisplay currentIndex={currentIndex}>
        {children}
      </SlidingDisplay>;
    default:
      return (
        <StaticDisplay>
          {children}
          <Caret {...caretProps} />
        </StaticDisplay>
      );
  }
};

export default TypingDisplay;

import React, { memo } from 'react';
import { Typography, Box, Tooltip } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import CustomAutoComplete from './CustomAutoComplete';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';


function UnstyledPatternsCard({ patterns, text, label, setValues, usePopupIcon, placeholder = "GIVE_ME_A_NAME", toolTipText, children, dataTour }) {
  
  //unstyled, don't use the box
  return (
    <>
      <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ width: "100%", m: "0" }}>
        <Grid xs={10}>
          <Typography>
            {text}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Box sx={{ padding: 0, textAlign: "right", width: "100%" }}>
            <Tooltip
              title={toolTipText}
              placement="left-start"
            >
              <InfoIcon />
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      {/*The patterns box*/}
      {/* <TTAutoComplete */}
      <CustomAutoComplete 
        usePopupIcon={usePopupIcon}
        optionsArr={[]}
        value={patterns}
        label={label}
        placeholder={placeholder}
        useFreeSolo={false}
        setValues={setValues}
      
      />
      {children}
    </>
  );
}

function PatternsCard({ patterns, text, label, setValues, usePopupIcon, placeholder = "GIVE_ME_A_NAME", toolTipText, children, dataTour}) {
  const theme = useTheme();

  return (
    <Box data-tour={dataTour}
      sx={{
        ...theme.customStyles.patternsBox,
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.paper,
        // opacity: (patterns.length !== 0) ? 1 : 0.5,
      }}
    >
      <UnstyledPatternsCard
        patterns={patterns}
        text={text}
        label={label}
        setValues={setValues}
        usePopupIcon={usePopupIcon}
        placeholder={placeholder}
        toolTipText={toolTipText}
        children={children}
        dataTour={dataTour}
      />
    </Box>
  );
}

const MemoizedPatternsCard = memo(PatternsCard);
const MemoizedUnstyledPatternsCard = memo(UnstyledPatternsCard);

export { MemoizedPatternsCard as PatternsCard, MemoizedUnstyledPatternsCard as UnstyledPatternsCard };
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { SuperMainContentContext } from '../../../contexts/SuperMainContext';
import { isRTL } from '../../TypingTest';
import { SlidingCaret } from '../../../components/SlidingCaret';
import { CharacterWidthMeasure } from './CharacterWidthMeasure.jsx';

const SlidingDisplay = ({ children }) => {
  const { selectedLanguage, isLoading } = useContext(SuperMainContentContext);
  const containerRef = useRef(null);

  return (
    <></>
  );
};

export default React.memo(SlidingDisplay);
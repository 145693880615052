import React, { useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';

import { TypingTestOptions } from './TypingTestOptions';
import { TypingTestResults } from './TypingTestResults';
import { MainContentContext } from '../contexts/MainContentContext';

function AboveTest({ setFocusMode }) {
  const { typingResultsAdvanced } = useContext(MainContentContext);

  const toggleFocusMode = (isOn) => {
    setFocusMode(isOn);
  };

  return (
    <Grid
      container
      data-tour="typing-test-options"
      alignItems="start"  // Changed from center to allow content to expand
      spacing={2}
    >
      <Grid 
        xs={12}
        md={typingResultsAdvanced ? 10 : 6} // More space when showing advanced results
        display="flex"
        justifyContent="center"
      >
        <TypingTestResults onToggleSwitch={toggleFocusMode} />
      </Grid>
      <Grid 
        xs={12} 
        md={typingResultsAdvanced ? 2 : 6} // Less space when showing advanced results
        display="flex" 
        justifyContent={typingResultsAdvanced ? "flex-start" : "center"}
        sx={{
          flexDirection: typingResultsAdvanced ? 'column' : 'row',
          alignItems: typingResultsAdvanced ? 'flex-start' : 'center',
          gap: typingResultsAdvanced ? 2 : 0
        }}
      >
        <TypingTestOptions />
      </Grid>
    </Grid>
  );
}

export { AboveTest };
import React, { useContext, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Divider, Box, Typography, Tooltip, Slider, Stack, Card } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

import { PatternsCard, UnstyledPatternsCard } from './PatternsCard'
import { InnerDashboard } from './InnerDashboard'
import { ProfilesMenu } from './ProfilesMenu'
import { SettingsCRUD } from './SettingsCRUD.jsx'
import { SliderWrapper, SliderWrapper2 } from './SliderWrapper'
import { MainContentContext  } from '../contexts/MainContentContext'
import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx'
import { RestoreDefaultSettingsButton } from './RestoreDefaultSettingsButton'
import {
  missedPatternsTooltip, slowPatternsTooltip, userIncludedPatternsTooltip,
  userExcludedPatternsTooltip, saveLoadTooltip
} from '../utils/toolTipTexts.js'
import { CustomPatternsDashboard } from './CustomPatternsDashboard'
import { KeyboardShortcuts } from './KeyboardShortcuts.jsx'


const TestParamsDashboard = () => {

  const theme = useTheme();

  const { inaccuratePatterns, setInaccuratePatterns, slowPatterns,
    setSlowPatterns, setSlowPatternsNum, setInaccuratePatternsNum,
    slowPatternsNum, inaccuratePatternsNum,  } = useContext(MainContentContext);


  const { includedPatterns, setIncludedPatterns, excludedPatterns, setExcludedPatterns,
  } = useContext(SuperMainContentContext);

  const handleInaccuratePatternsSlider = (event, newValue) => {
      setInaccuratePatternsNum(newValue);
  };

  const handleSlowPatternsSlider = (event, newValue) => {
      setSlowPatternsNum(newValue);
  };

  return (
    <Grid container spacing={4}>
      <Grid xs={12} sm={12} lg={6} >
        <PatternsCard
          dataTour="inaccurate-patterns"
          patterns={inaccuratePatterns}
          text="Your Mistyped Patterns"
          placeholder='Inaccurate Patterns'
          setValues={setInaccuratePatterns}
          toolTipText={missedPatternsTooltip} >
          <SliderWrapper
            label={`inaccurate Patterns: ${inaccuratePatternsNum}`}
            tooltipTitle={"Adjust the number of used mistyped patterns for the targeted practice"}
            tooltipPlacement="left-start"
          >
            <Slider
              color="secondary"
              value={inaccuratePatternsNum}
              onChange={handleInaccuratePatternsSlider}
              step={1}
              marks={[
                { value: 1, label: '1' },
                { value: 5, label: '5' },
                { value: 10, label: '10' },
                // { value: 15, label: '15' }
              ]}
              min={1}
              max={10}
              sx={{
                width: "80%", mt: 0, ml: "1em",
                '& .MuiSlider-markLabel': {
                  color: 'text.primary', // Use primary text color for all labels
                },
              }}
            />
          </SliderWrapper>
        </PatternsCard>
        <br />

        <Box data-tour="custom-user-patterns"
          sx={{
            ...theme.customStyles.patternsBox,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.background.paper,
            // opacity: (patterns.length !== 0) ? 1 : 0.5,
          }}
        >

          <UnstyledPatternsCard
            patterns={includedPatterns}
            text="Include Patterns"
            placeholder='Include patterns'
            label="Enter desired patterns"
            setValues={setIncludedPatterns}
            toolTipText={userIncludedPatternsTooltip} />
          <br />
          <UnstyledPatternsCard
            patterns={excludedPatterns}
            text="Exclude Patterns"
            placeholder='Exclude patterns'
            label="Enter undesired patterns"
            setValues={setExcludedPatterns}
            toolTipText={userExcludedPatternsTooltip} />
          {/* <br /> */}
        </Box>
        {/* <TTAccordion text="User Custom Patterns"
          Icon={DashboardCustomizeIcon}
          expanded={customPatternsaccordionExpanded}
          setExpanded={setCustomPatternsaccordionExpanded}
          width="100%"
        > */}
  
        {/* <SettingsCRUD/> */}
        {/* </TTAccordion> */}
        {/* <br /> */}
        {/* <RestoreDefaultSettingsButton /> */}
        {/* <br /> */}
        {/* <KeyboardShortcuts/> */}
      </Grid>
      <Grid xs={12} sm={12} lg={6}>
        <PatternsCard
          dataTour="slow-patterns"
          patterns={slowPatterns}
          text="Your Slow Patterns"
          setValues={setSlowPatterns}
          placeholder='Slow Patterns'
          toolTipText={slowPatternsTooltip} >
          <SliderWrapper
            label={`Slow Patterns: ${slowPatternsNum}`}
            tooltipTitle={"Adjust the number of used slow patterns for the targeted practice"}
            tooltipPlacement="left-start"
          >
            <Slider
              color="secondary"
              value={slowPatternsNum}
              onChange={handleSlowPatternsSlider}
              step={1}
              marks={[
                { value: 1, label: '1' },
                { value: 5, label: '5' },
                { value: 10, label: '10' },
                // { value: 15, label: '15' }
              ]}
              min={1}
              max={10}
              sx={{
                width: "80%", mt: 0, ml: "1em",
                '& .MuiSlider-markLabel': {
                  color: 'text.primary', // Use primary text color for all labels
                },
              }} />
          </SliderWrapper>
        </PatternsCard>
        <br />
        <CustomPatternsDashboard />
        {/* <TTAccordion text="Advanced Settings"
          Icon={TuneIcon}
          expanded={innerDashboardAccordionExpanded}
          setExpanded={setInnerDashboardAccordionExpanded}
          width="100%"
        > */}
        {/* <InnerDashboard /> */}
        {/* </TTAccordion> */}
      </Grid>
    </Grid>
  );
}

export {TestParamsDashboard}
import React from 'react';
import { Typography, List, ListItem, ListItemText, Box } from '@mui/material';

const features = [
  {
    primary: "Pattern Analysis",
    secondary: "Typecelerate analyzes your typing performance and pinpoints specific letter combinations and words that slow you down or cause errors."
  },
  {
    primary: "Custom Practice",
    secondary: "Based on the analysis, Typecelerate generates custom practice exercises to target your weak points and improve your overall typing speed and accuracy."
  },
  {
    primary: "Progress Tracking",
    secondary: "Track your progress over time with detailed statistics and visualizations, helping you stay motivated and see your improvement."
  },
  {
    primary: "Adaptive Learning",
    secondary: "The tool adapts to your skill level, providing more challenging exercises as you improve, ensuring continuous growth."
  },
  {
    primary: "Real-time Feedback",
    secondary: "Receive real-time feedback on your typing performance, allowing you to make immediate corrections and adjustments."
  },
  {
    primary: "Personal Bests",
    secondary: "We save personal bests for bragging rights."
  }
];

const AppInfo = () => {
  return (
    <Box sx={{ p: 2, width: "50%" }}>
      <Typography variant="h4" gutterBottom>
        Typecelerate
      </Typography>
      
      <Typography variant="body1" paragraph>
        Typecelerate is designed to help you improve your typing skills through detailed analysis and customized practice. Whether you're a beginner or an advanced typist, our tool offers features to support your growth.
      </Typography>
      
      <Typography variant="h5" gutterBottom>
        Key Features:
      </Typography>
      <List>
        {features.map((feature, index) => (
          <ListItem key={index}>
            <ListItemText 
              primary={<Typography variant="h6">{feature.primary}</Typography>}
              secondary={
                <Typography variant="body1" style={{ marginTop: '0.5em' }}>
                  {feature.secondary}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>

      <Typography variant="h6" gutterBottom>
        How to Use:
      </Typography>
      <Typography variant="body1" paragraph>
        You can start typing, and Typecelerate takes care of the rest.
        If you want to customize your practice, you can adjust the test settings to your liking.
        If you're not sure what a particular feature does, hover over the info icon for more information.
        Don't be afraid to play around with the settings, there's a 'Restore Defaults' button if you want to start fresh.
        Current keyboard shortcuts include: esc to quit the test, and ctrl+shift+f to focus the typing test box.
      </Typography>

      <Typography variant="body1" paragraph sx={{ mt: 2 }}>
        Remember, consistent practice is key to improving your typing skills. Use Typecelerate regularly, focus on your weak areas, and watch your speed and accuracy improve over time!
      </Typography>
    </Box>
  );
};

export { AppInfo };
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Stack, Card, Divider, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';

function TTAccordion({ text, children, Icon, expanded, setExpanded, width="80%" }) {
  const theme = useTheme();

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      square={true}
      style={{
        boxShadow:
          "none"
      }}
      expanded={expanded}
      defaultExpanded={true}
      disableGutters
      sx={{
        width: width,
        margin: '0 auto',
        overflow: 'hidden', // Ensure content doesn't overflow rounded corners
        mb: 0,
        '&:before': {
          display: 'none',
        },
        // '& .MuiAccordionDetails-root': {
        //   borderRadius: '0 0 20px 20px',
        // },
        // '&:first-of-type, &:last-of-type': {
        //   borderRadius: '20px',
        // },
        // '& .MuiCollapse-root': {
        //   borderRadius: '0 0 20px 20px',
        // },
      }}
      TransitionProps={{ unmountOnExit: false }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
        sx={{
          backgroundColor: theme.palette.primary.light,
          '& .MuiAccordionSummary-content': {
            justifyContent: 'center',
          },
        }}
        onClick={handleChange}
      >
        <Divider sx={{
          width: '100%',
        }}>
          <Card
            sx={{
              borderRadius: 20,
              // bgcolor: theme.palette.background.default,
              bgcolor: theme.palette.background.paper,
              color: theme.palette.text.secondary,
            }}
            variant="outlined" >
            <Stack direction="row">
              <Icon sx={{ mx: '10px' }} />
              <Typography variant="p" sx={{ pr: "30px" }}  >
                {text}
              </Typography>
            </Stack>
          </Card>
        </Divider>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: theme.palette.primary.light, }} >
        {children}
      </AccordionDetails>
    </Accordion>
  );

}

export { TTAccordion }

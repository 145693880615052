import React, { useState, useContext, useCallback, useEffect } from 'react';
import { TextField, MenuItem, Select, IconButton, Box, Button, Typography } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { MainContentContext  } from '../contexts/MainContentContext'
import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx'
import { AppContext } from '../contexts/AppContext';
import { getProfiles, addProfile, deleteProfile, modifyProfile, getProfile } from '../utils/firestoreUtils';
import { builtInProfiles } from '../utils/builtInProfiles';

//TODO nimw - Claude suggested I use useReducer for this component
//It even gave me working code (I tested manually), but I don't want to do it before I understand it

// For the saving/loading profile part
const ProfilesMenu = () => {
  const { currUser,
    isUserLoading
  } = useContext(AppContext);
  const { profileOptions,
    setProfileOptions,
    loadedProfile,
    setLoadedProfile
  } = useContext(MainContentContext);
  const {
    setIncludedPatterns,
    includedPatterns,
    setExcludedPatterns,
    excludedPatterns
  } = useContext(SuperMainContentContext);
  const [currProfile, setCurrProfile] = useState('');
  const [error, setError] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [msg, setMsg] = useState('');
  const [isMsgVisible, setIsMsgVisible] = useState(false);

  const handleModifyProfile = async (userId, profile) => {
    if (!loadedProfile) {
      setError('Please select a profile to modify');
      return;
    }

    try {
      await modifyProfile(userId, profile);
      setError(null); // Clear any previous errors on success
      setMsg('Profile modified successfully');
    } catch (error) {
      console.error('Error modifying profile:', error);
      setMsg(null);
      setError('Failed to modify profile. Please try again.');
    }
  };

  const handleProfileNameChange = (event) => {
    setCurrProfile(event.target.value);
  };

  //setOptions according to the profiles in the database, if first ever login, setOptions to builtInProfiles
  useEffect(() => {
    if (isUserLoading || !currUser) {
      return;
    }
    getProfiles(currUser.uid).then((profiles) => {
      if (profiles.length === 0) {
        // First login - create built-in profiles
        Object.entries(builtInProfiles).forEach(([name, profileData]) => {
          addProfile(currUser.uid, {
            name,
            include: profileData.include,
            exclude: profileData.exclude
          });
        });
        // Set options to built-in profile names
        setProfileOptions(Object.keys(builtInProfiles));
      } else {
        // Extract profile names from the id field of each profile object
        setProfileOptions(profiles.map(profile => profile.id));
      }
    });
  }, [currUser, isUserLoading]);


  const handleProfileNameKeyPress = (event) => {
    if ((event.key === 'Enter' || event.type === 'click') && currProfile.trim() !== '') {
      setProfileOptions([...profileOptions, currProfile.trim()]);
      setCurrProfile('');
      setLoadedProfile(currProfile.trim());
      addProfile(currUser.uid, {
        include: includedPatterns,
        exclude: excludedPatterns,
        name: currProfile.trim()
      });
      // addOptionToDB(inputValue.trim(), includedPatterns, excludedPatterns);
    }
  };

  const handleOptionClick = (selectedOption) => {
    setLoadedProfile(selectedOption);
    getProfile(currUser.uid, selectedOption).then((profile) => {
      setIncludedPatterns(profile.include);
      setExcludedPatterns(profile.exclude);
    });
  };

  const handleDeleteOption = (index) => {
    const optionToDelete = profileOptions[index];
    const updatedOptions = profileOptions.filter((_, i) => i !== index);
    setProfileOptions(updatedOptions);
    deleteProfile(currUser.uid, optionToDelete);
    if (loadedProfile === optionToDelete) {
      setLoadedProfile('');
    }
  };

  useEffect(() => {
    let timeoutId;
    if (error) {
      setIsErrorVisible(true);
      timeoutId = setTimeout(() => {
        setIsErrorVisible(false);
        // Wait for fade transition to complete before clearing error
        setTimeout(() => setError(null), 300);
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [error]);

  useEffect(() => {
    let timeoutId;
    if (msg) {
      setIsMsgVisible(true);
      timeoutId = setTimeout(() => {
        setIsMsgVisible(false);
        // Wait for fade transition to complete before clearing message
        setTimeout(() => setMsg(null), 300);
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [msg]);

  return (
    <Box>
      <Grid container spacing={2} sx={{ width: '100%' }}>
        <Grid xs={8}>
          <TextField
            label="New Profile Name..."
            value={currProfile}
            onChange={handleProfileNameChange}
            onKeyPress={handleProfileNameKeyPress}
            size="small"
            fullWidth
            disabled={!currUser}
          />
        </Grid>
        <Grid xs={4}>
          <Button
            variant='contained'
            fullWidth
            onClick={handleProfileNameKeyPress}
            disabled={!currUser}
          >
            <Typography align='left'>Create</Typography>
          </Button>
        </Grid>
        <Grid xs={12}>
          <Select
            data-tour="profiles-menu"
            value=""
            onChange={(event) => handleOptionClick(event.target.value)}
            displayEmpty
            variant="outlined"
            size="small"
            fullWidth
            disabled={!currUser}
            sx={{
              '& .MuiSelect-select': {
                width: '100%',
                minWidth: '200px', // Ensures minimum width
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }
            }}
          >
            <MenuItem value="" disabled>
              {loadedProfile === ''
                ? 'Load Profile...'
                : loadedProfile
              }
            </MenuItem>
            {profileOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  {option}
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDeleteOption(index);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button
            variant='contained'
            onClick={() => handleModifyProfile(
              currUser.uid, {
              include: includedPatterns,
              exclude: excludedPatterns,
              name: loadedProfile
            })}
            disabled={!currUser}
            sx={{
              '& .MuiTypography-root': {
                transition: 'color 0.3s ease',
                color: isErrorVisible ? 'error.main' : (isMsgVisible ? 'success.main' : 'inherit')
              }
            }}
          >
            <Typography>
              {isErrorVisible ? error : (isMsgVisible ? msg : 'Modify Current Profile')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export { ProfilesMenu};


import React, { useState, useContext, useEffect } from 'react';
import {
  TextField,
  MenuItem,
  Select,
  IconButton,
  Box,
  Button,
  Typography,
  Tooltip
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { MainContentContext  } from '../contexts/MainContentContext'
import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx'
import { AppContext } from '../contexts/AppContext';
import { getPresets, addPreset, deletePreset, modifyPreset, getPreset } from '../utils/firestoreUtils';
import { builtInPresets } from '../utils/builtInPresets';
import { useTheme } from '@mui/material/styles';
import { saveLoadSettingPresetTooltip } from '../utils/toolTipTexts.js'
import InfoIcon from '@mui/icons-material/Info';



// For the saving/loading preset part
const SettingsCRUD = () => {
  const theme = useTheme();
  const { currUser,
    isUserLoading
  } = useContext(AppContext);
  const {
    testSettings,
    setTestSettings,
    desiredRandomness,
    setDesiredRandomness,
    wordRepetition,
    setWordRepetition,
    randomDuration,
    setRandomDuration,
    selectedTestLength,
    selectedWordList,
    selectedLanguage,
    setSelectedTestLength,
    setSelectedWordList,
    setSelectedLanguage,
    punctuation,
    setPunctuation,
  } = useContext(SuperMainContentContext);
  const {
    presetOptions,
    setPresetOptions,
    loadedPreset,
    setLoadedPreset,
    desiredWeaknessHistory,
    setDesiredWeaknessHistory,
    desiredWordsHistory,
    setDesiredWordsHistory,
    inaccuratePatternTemplates,
    setInaccuratePatternTemplates,
    slowPatternTemplates,
    setSlowPatternTemplates,
    setInaccuratePatternsNum,
    setSlowPatternsNum,
    inaccuratePatternsNum,
    slowPatternsNum,
    desiredRefreshInterval,
    setDesiredRefreshInterval,
  } = useContext(MainContentContext);
  const [currPreset, setCurrPreset] = useState('');
  const [error, setError] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [msg, setMsg] = useState('');
  const [isMsgVisible, setIsMsgVisible] = useState(false);

  const handleModifyPreset = async (userId, preset) => {
    if (!loadedPreset) {
      setError('Please select a preset to modify');
      return;
    }

    try {
      await modifyPreset(userId, preset);
      setError(null); // Clear any previous errors on success
      setMsg('Preset modified successfully');
    } catch (error) {
      console.error('Error modifying preset:', error);
      setMsg(null);
      setError('Failed to modify preset. Please try again.');
    }
  };

  const handlePresetNameChange = (event) => {
    setCurrPreset(event.target.value);
  };

  //setOptions according to the presets in the database, if first ever login, setOptions to builtInPresets
  useEffect(() => {
    if (isUserLoading || !currUser) {
      return;
    }
    getPresets(currUser.uid).then((presets) => {
      if (presets.length === 0) {
        // First login - create built-in presets
        Object.entries(builtInPresets).forEach(([name, presetData]) => {
          addPreset(currUser.uid, {
            name,
            settings: presetData.settings
          });
        });
        // Set options to built-in preset names
        setPresetOptions(Object.keys(builtInPresets));
      } else {
        // Extract preset names from the id field of each preset object
        setPresetOptions(presets.map(preset => preset.id));
      }
    });
  }, [currUser, isUserLoading]);


  const getCurrentSettings = () => {
    return {
      // Test configuration
      language: selectedLanguage,
      testLength: selectedTestLength,
      wordList: selectedWordList,
    
      // Weakness detection
      inaccuratePatternTemplates: inaccuratePatternTemplates,
      slowPatternTemplates: slowPatternTemplates,
    
      // Number settings
      weaknessHistory: desiredWeaknessHistory,
      wordsHistory: desiredWordsHistory,
      refreshInterval: desiredRefreshInterval,
      inaccuratePatternsCount: inaccuratePatternsNum,
      slowPatternsCount: slowPatternsNum,
      randomness: desiredRandomness,
      randomDuration: randomDuration,
      wordRepetition: wordRepetition,
    
      // Flags
      punctuation: punctuation
    };
  };

  const handlePresetNameKeyPress = (event) => {
    if ((event.key === 'Enter' || event.type === 'click') && currPreset.trim() !== '') {
      setPresetOptions([...presetOptions, currPreset.trim()]);
      setCurrPreset('');
      setLoadedPreset(currPreset.trim());
      addPreset(currUser.uid, {
        name: currPreset.trim(),
        settings: getCurrentSettings()
      });
    }
  };

const handleOptionClick = (selectedOption) => {
  setLoadedPreset(selectedOption);
  getPreset(currUser.uid, selectedOption).then((preset) => {
    // Guard against undefined preset or settings
    if (!preset || !preset.settings) {
      console.error('Invalid preset data');
      return;
    }

    console.log('Loaded preset:', preset);
    
    const settings = preset.settings;
    
    // Test configuration
    if (settings.language) setSelectedLanguage(settings.language);
    if (settings.testLength) setSelectedTestLength(settings.testLength);
    if (settings.wordList) setSelectedWordList(settings.wordList);
    
    // Weakness detection - ensure arrays are never undefined
    setInaccuratePatternTemplates(settings.inaccuratePatternTemplates || []);
    setSlowPatternTemplates(settings.slowPatternTemplates || []);
    
    // Number settings - provide defaults if undefined
    setDesiredWeaknessHistory(settings.weaknessHistory ?? 6);
    setDesiredWordsHistory(settings.wordsHistory ?? 6);
    setDesiredRefreshInterval(settings.refreshInterval ?? 10);
    setInaccuratePatternsNum(settings.inaccuratePatternsCount ?? 5);
    setSlowPatternsNum(settings.slowPatternsCount ?? 5);
    setDesiredRandomness(settings.randomness ?? 5);
    setRandomDuration(settings.randomDuration ?? 1);
    setWordRepetition(settings.wordRepetition ?? 1);
    
    // Flags
    setPunctuation(settings.punctuation ?? false);
  });
};

  const handleDeleteOption = (index) => {
    const optionToDelete = presetOptions[index];
    const updatedOptions = presetOptions.filter((_, i) => i !== index);
    setPresetOptions(updatedOptions);
    deletePreset(currUser.uid, optionToDelete);
    if (loadedPreset === optionToDelete) {
      setLoadedPreset('');
    }
  };

  useEffect(() => {
    let timeoutId;
    if (error) {
      setIsErrorVisible(true);
      timeoutId = setTimeout(() => {
        setIsErrorVisible(false);
        // Wait for fade transition to complete before clearing error
        setTimeout(() => setError(null), 300);
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [error]);

  useEffect(() => {
    let timeoutId;
    if (msg) {
      setIsMsgVisible(true);
      timeoutId = setTimeout(() => {
        setIsMsgVisible(false);
        // Wait for fade transition to complete before clearing message
        setTimeout(() => setMsg(null), 300);
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [msg]);

  return (
      <Box data-tour="save-load-presets"
        sx={{
          ...theme.customStyles.patternsBox,
          borderColor: theme.palette.divider,
          backgroundColor: theme.palette.background.paper,
          my: '1.5em',
        }}
      >
    <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ width: "100%", m: "0" }}>
      <Grid xs={10}>
        <Typography sx={{ mb: "1em" }}>Save Settings Presets</Typography>
      </Grid>
      <Grid xs={2}>
        <Box sx={{ padding: 0, textAlign: "right", width: "100%" }}>
          <Tooltip
            title={saveLoadSettingPresetTooltip}
            placement="left-start"
          >
            <InfoIcon />
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
        <Box>
          <Grid container spacing={2} sx={{ width: '100%' }}>
            <Grid xs={8}>
              <TextField
                label="New Preset Name..."
                value={currPreset}
                onChange={handlePresetNameChange}
                onKeyPress={handlePresetNameKeyPress}
                size="small"
                fullWidth
                disabled={!currUser}
              />
            </Grid>
            <Grid xs={4}>
              <Button
                variant='contained'
                fullWidth
                onClick={handlePresetNameKeyPress}
                disabled={!currUser}
              >
                <Typography align='left'>Create</Typography>
              </Button>
            </Grid>
            <Grid xs={12}>
              <Select
                data-tour="presets-menu"
                value=""
                onChange={(event) => handleOptionClick(event.target.value)}
                displayEmpty
                variant="outlined"
                size="small"
                fullWidth
                disabled={!currUser}
                sx={{
                  '& .MuiSelect-select': {
                    width: '100%',
                    minWidth: '200px', // Ensures minimum width
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }
                }}
              >
                <MenuItem value="" disabled>
                  {loadedPreset === ''
                    ? 'Load Preset...'
                    : loadedPreset
                  }
                </MenuItem>
                {(presetOptions || []).map((option, index) => (
                  <MenuItem key={index} value={option}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                      {option}
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteOption(index);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Button
                variant='contained'
                onClick={() => handleModifyPreset(
                  currUser.uid, {
                    name: loadedPreset,
                    settings: getCurrentSettings()
                })}
                disabled={!currUser}
                sx={{
                  '& .MuiTypography-root': {
                    transition: 'color 0.3s ease',
                    color: isErrorVisible ? 'error.main' : (isMsgVisible ? 'success.main' : 'inherit')
                  }
                }}
              >
                <Typography>
                  {isErrorVisible ? error : (isMsgVisible ? msg : 'Modify Current Preset')}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
  );
};

export { SettingsCRUD };

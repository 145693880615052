import React  from 'react';
import { Tooltip, useTheme } from '@mui/material';



const AvatarIconWrapper = ({ icon: Icon, progress, tooltipText, tooltipOpen, isFocused, visible }) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={tooltipText}
      placement="right-end"
      arrow
      open={tooltipOpen && isFocused && visible}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-20, 0],
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            whiteSpace: 'nowrap',
            maxWidth: 'none',
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            '& .MuiTooltip-arrow': {
              color: theme.palette.background.paper,
            },
          },
        },
      }}
    >
      <Icon
        sx={{
          position: 'absolute',
          top: '-140%', //This deternines the vertical position of the icon
          left: `${progress}%`,
          // transform: 'translateX(-50%)',
          transition: 'left 0.3s ease-in-out',
          color: theme.palette.text.primary,
        }}
      />
    </Tooltip>
  );
};

export { AvatarIconWrapper };
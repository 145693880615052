// import { getText } from '../utils/textGenerator';

//new: single test analysis -> history manager -> pattern aggregator -> pattern arbiter -> patterns packer -> stateSetter & test generator
import { defaultLetterLimit } from '../utils/defaultValues.js';

// Synchronous delay function
function delay(milliseconds) {
  const start = Date.now();
  while (Date.now() - start < milliseconds) {
    // Do nothing, just block the execution
  }
}

// const delay = (ms) => {
//   const start = Date.now();
//   while (Date.now() - start < ms) {
//     // Delaying in a busy-wait loop
//   }
// }

class Metadata {
  constructor() {
    this.originalText = '';
    this.userText = '';
    this.errorIndices = [];
    this.startTime = 0;
    this.timeToType = [];
  }
}

class DesiredWeaknessPatterns {
  constructor() {
    this.slowLetters = true;
    this.slowBigrams = true;
    this.slowTrigrams = true;
    this.slowWords = true;
    this.slowSpacegrams = true;
    this.errorneousLetters = true;
    this.errorneousBigrams = true;
    this.errorneousTrigrams = true;
    this.errorneousWords = true;
    this.errorneousSpacegrams = true;
  }
}

class PackedPatterns {
  constructor() {
    this.errorArr = [];
    this.slowArr = [];
  }

  clone() {
    const copy = new PackedPatterns();
    copy.errorArr = [...this.errorArr];
    copy.slowArr = [...this.slowArr];
    return copy;
  }
}

class ErrorPattern {
  constructor(pattern) {
    this.pattern = pattern;
    this.errorCountInTest = 0;
    this.appearanceCountInText = 0;
    this.errorRate = 0;
  }

  incrErrorCount() {
    this.errorCountInTest++;
  }

  setAppearanceCount(numAppearances) {
    this.appearanceCountInText = numAppearances;
  }

  calculateErrorRate() {
    this.errorRate = this.errorCountInTest / this.appearanceCountInText;
  }
}

class TimePattern {
  constructor(pattern, time) {
    this.pattern = pattern;
    this.time = time;
    this.wpm = this.calculateWPM(pattern, time);
  }

  calculateWPM(pattern, time) {
    //hello
    const tmpPattern = pattern.replace(/\\b/g, '');
    const numChars = tmpPattern.length;
    // const numChars = pattern.length;
    const minutes = time / 60000;
    const words = numChars / 5;
    const result = Math.floor(words / minutes);
    console.log("pattern: " + pattern + ", tmpPattern" + tmpPattern +", time: " + time + ", numChars: " + numChars + ", minutes: " + minutes + ", words: " + words + ", wpm: " + this.wpm);
    return result;
  }
}

class PatternWeakenssesAggregator {
  constructor(
    letterErrorPatternsArr,
    bigramErrorPatternsArr,
    trigramErrorPatternsArr,
    wordErrorPatternsArr,
    spacegramErrorPatternsArr,
    letterTimePatternsArr,
    bigramTimePatternsArr,
    trigramTimePatternsArr,
    wordTimePatternsArr,
    spacegramTimePatternsArr
  ) {
    //console.log("PatternsArrWeakenssesAggregator: constructor");
    this.letterErrorPatternsArr = letterErrorPatternsArr
    this.bigramErrorPatternsArr = bigramErrorPatternsArr
    this.trigramErrorPatternsArr = trigramErrorPatternsArr
    this.wordErrorPatternsArr = wordErrorPatternsArr
    this.spacegramErrorPatternsArr = spacegramErrorPatternsArr
    this.letterTimePatternsArr = letterTimePatternsArr
    this.bigramTimePatternsArr = bigramTimePatternsArr
    this.trigramTimePatternsArr = trigramTimePatternsArr
    this.wordTimePatternsArr = wordTimePatternsArr
    this.spacegramTimePatternsArr = spacegramTimePatternsArr
    //console.log(JSON.parse(JSON.stringify(this)));
  }
}

class SingleTestAnalysis {

  constructor(isFullyRandom, setTestStatus) {
    //input
    this.testMetaData = new Metadata();

    //outputs
    this.letterTTTarr = [];
    this.bigramTTTarr = [];
    this.trigramTTTarr = [];
    this.wordTTTarr = [];
    this.spacegramTTTarr = [];

    this.letterErrorArr = []
    this.bigramErrorArr = [];
    this.trigramErrorArr = [];
    this.wordErrorArr = [];
    this.spacegramErrorArr = [];

    this.WPM = 0;
    // this.setSlowPatterns = null;
    // this.setInaccuratePatterns = null;
    this.slowPatterns = null;
    this.inaccuratePatterns = null;
    this.desiredWeaknessPatterns = null;
    this.desiredWeaknessHistory = 3;
    this.desiredWordsHistory = 3;

    //intermediate helpers
    this.historyManager = new HistoryManager();
    this.testGenerator = new TestGenerator();
    this.patternStateSetter = new PatternStateSetter();
    this.patternPacker = new PatternsPacker();
    this.userWantedPatterns = [];
    this.userUnwantedPatterns = [];
    this.isFullyRandom = isFullyRandom;
    this.setTestStatus = setTestStatus;
  }

  clearHistory() {
    this.historyManager = new HistoryManager();
  }

  setUserRequestedPatterns(includedPatterns, excludedPatterns) {
    this.userWantedPatterns = includedPatterns;
    this.userUnwantedPatterns = excludedPatterns;
  }

  getPerformanceMetrics(testMetaData, desiredWeaknessPatterns, desiredWeaknessHistory,
    setSlowPatterns, setInaccuratePatterns, slowPatterns, inaccuratePatterns,
    includedPatterns, excludedPatterns, inaccuratePatternsNum, slowPatternsNum,
    freezeHistory, desiredWordsHistory) {
    // this.setSlowPatterns = setInaccuratePatterns;
    // this.setInaccuratePatterns = setSlowPatterns;
    this.slowPatterns = slowPatterns;
    this.inaccuratePatterns = inaccuratePatterns;
    this.setUserRequestedPatterns(includedPatterns, excludedPatterns);
    //console.log("desiredHistory = " + desiredHistory)
    this.desiredWeaknessHistory = desiredWeaknessHistory;
    this.desiredWordsHistory = desiredWordsHistory;
    this.setTestMetaData(testMetaData);
    this.desiredWeaknessPatterns = desiredWeaknessPatterns;
    //console.log(JSON.parse(JSON.stringify(this.desiredWeaknessPatterns)));
    // populate the weakness arrays in a special format to help the PatternArbiter
    //These 2 functions are super duper important, and badly named.
    const WPM = this.getLatestWPM();
    const accuracy = this.getLatestAccuracy();
    if (!freezeHistory) {
      // Aggregate the ararys to a single object to pass to HistoryManager
      // Only the arrays that the user wanted will have content. The rest will stay empty.
      const currTestAggregatedPatterns = new PatternWeakenssesAggregator(
        this.letterErrorArr,
        this.bigramErrorArr,
        this.trigramErrorArr,
        this.wordErrorArr,
        this.spacegramErrorArr,
        this.letterTTTarr,
        this.bigramTTTarr,
        this.trigramTTTarr,
        this.wordTTTarr,
        this.spacegramTTTarr
      );
      console.log("currTestAggregatedPatterns: " + JSON.parse(JSON.stringify(currTestAggregatedPatterns)));

      this.historyManager.setDesiredWeaknessHistory(desiredWeaknessHistory);
      //Give the history manager information about the latest test.
      this.historyManager.addTestAggregatedPatterns(currTestAggregatedPatterns)
      //The history manager will purge any information that is older than the desired history.
      this.historyManager.removeOldPatterns();
      //The patternArbiter will decide which patterns to use based on the patterns the user is worst at.
      //No priority is given to newer results. The worst patterns are always used.
      const patternArbiter = new PatternArbiter();
      patternArbiter.setAggregatedPatterns(this.historyManager.getLatestCombinedAggregatedWeaknesses());
      patternArbiter.setDesiredInaccuratePatternsNum(inaccuratePatternsNum);
      patternArbiter.setDesiredSlowPatternsNum(slowPatternsNum);
      patternArbiter.arbitratePatterns();
      console.log(patternArbiter);

      this.patternPacker.setArbitratedSlowPatterns(patternArbiter.getArbitratedSlowPatterns());
      this.patternPacker.setArbitratedInaccuratePatterns(patternArbiter.getArbitratedInaccuratePatterns());
      this.patternPacker.setDesiredWeaknessHistory(desiredWeaknessHistory);
      this.patternPacker.setUIslowPatterns(slowPatterns);
      this.patternPacker.setUIinaccuratePatterns(inaccuratePatterns);
      this.patternPacker.packPatterns();
      this.patternPacker.manipulateHistoryBasedOnUserAdditionsAndDeletionsFromUI();
      console.log(this.patternPacker);

      this.patternStateSetter.setPackedPatterns(this.patternPacker.getPackedPatterns());
      this.patternStateSetter.setSlowPatternsSetter(setSlowPatterns);
      this.patternStateSetter.setInaccuratePatternsSetter(setInaccuratePatterns);
      this.patternStateSetter.setPatternsBoxes();
      console.log(this.patternStateSetter);
    }
    return { WPM, accuracy };
  }

  setTestMetaData(testMetaData) {
    console.log("setTestMetaData")
    if (testMetaData.userText.length !== testMetaData.originalText.length)
      throw new Error("userText to originalText length mismatch")
    if (testMetaData.timeToType.length !== testMetaData.originalText.length)
      throw new Error("timeToType to originalText length mismatch")
    this.testMetaData = testMetaData;
    console.log(JSON.parse(JSON.stringify(testMetaData)))
  };

  getLatestWPM() {
    this.clearTimeArrays();
    const originalText = this.testMetaData.originalText;
    const userText = this.testMetaData.userText;
    const textLen = originalText.length;
    const originalTextArray = Array.from(originalText);
    const correctLetters = originalTextArray.filter((char, index) => char === userText[index]);
    const correctLetterCount = correctLetters.length;
    const timeToTypeSeconds = this.testMetaData.timeToType[textLen - 1] / 1000;
    const CharactersPerSecond = correctLetterCount / timeToTypeSeconds;
    const secondsInMinute = 60;
    const CharactersPerMinute = secondsInMinute * CharactersPerSecond;
    const wordLen = 5;
    this.WPM = Math.floor(CharactersPerMinute / wordLen);
    if (!(this.WPM !== Infinity && this.WPM !== null && this.WPM >= 0)) throw new Error("WPM calculation is invalid")

    console.log(
      `
        Original Text: ${originalText}
        Original Text Length: ${textLen}
        User Text: ${userText}
        Length of Text Written Correctly: ${correctLetterCount}
        Time to Type (in seconds): ${timeToTypeSeconds}
        Average Word Length: ${wordLen}
        Characters Per Minute (CPM): ${CharactersPerMinute}
        Words Per Minute (WPM): ${this.WPM}
      `);
    console.log(JSON.parse(JSON.stringify(this.desiredWeaknessPatterns)));
    if (this.desiredWeaknessPatterns.slowLetters)
      this.populateTimeLetters();
    if (this.desiredWeaknessPatterns.slowBigrams)
      this.populateTimeBigrams();
    if (this.desiredWeaknessPatterns.slowTrigrams)
      this.populateTimeTrigrams();
    if (this.desiredWeaknessPatterns.slowWords)
      this.populateTimeWords();
    if (this.desiredWeaknessPatterns.slowSpacegrams)
      this.populateTimeSpacegrams();
    return this.WPM;
  };

  getLatestAccuracy() {
    this.clearErrorArrays();
    const originalText = this.testMetaData.originalText;
    const textLen = originalText.length;
    const originalTextArray = Array.from(originalText);
    const userText = this.testMetaData.userText;
    const userTextArray = Array.from(userText);
    const correctLetters = originalTextArray.filter((char, index) => char === userTextArray[index]);
    const correctLetterCount = correctLetters.length;
    const altCorrectLetterCount = this.testMetaData.errorIndices.length;
    const Accuracy = Math.floor(100 * correctLetterCount / textLen);
    const altAccuracy = Math.floor(100 * (textLen - altCorrectLetterCount) / textLen);
    console.log("errorIndices length: " + this.testMetaData.errorIndices.length);
    console.log("textLen: " + textLen);
    console.log("altCorrectLetterCount: " + altCorrectLetterCount);
    console.log("altAccuracy: " + altAccuracy);
    if (this.desiredWeaknessPatterns.errorneousLetters ||
      this.desiredWeaknessPatterns.errorneousBigrams ||
      this.desiredWeaknessPatterns.errorneousTrigrams ||
      this.desiredWeaknessPatterns.errorneousWords ||
      this.desiredWeaknessPatterns.errorneousSpacegrams
    ) {
      this.populateErrorneousPatterns();
    }
    return altAccuracy;
    // return Accuracy;
  };

  // asyncGetNextTest(someInteger) {
  //   return new Promise((resolve, reject) => {
  //     resolve(this.getNextTest(someInteger));
  //   });
  // }

  getNextTest(wordCount,
    wordPool,
    desiredRandomness = 0,
    removeOutputsFromPool = true,
    setAlgorithmFoundWords,
    letterLimit,
    punctuation = false,
    wordRepetition = 1
  ) {

    //print all inputs as an object
    console.log({
      wordCount,
      // wordPool,
      desiredRandomness,
      removeOutputsFromPool,
      setAlgorithmFoundWords,
      letterLimit,
      punctuation,
      wordRepetition
    });

    const wantedPatterns = this.patternPacker.getReallyPackedPatterns();
    if (desiredRandomness === 100) {
      letterLimit = defaultLetterLimit;
    }
    this.isFullyRandom.current =
      (
        (
          (desiredRandomness === 100 && this.userUnwantedPatterns.length === 0) ||
          (wantedPatterns.length === 0 && this.userWantedPatterns.length === 0 && this.userUnwantedPatterns.length === 0)
        )
      );

    const wordPoolWithRepetitions = Array(wordRepetition).fill(wordPool).flat();

    this.testGenerator.setWordPool(wordPoolWithRepetitions);
    //concat the history flat array with this.userWantedPatterns
    this.testGenerator.setWantedPatterns(wantedPatterns);
    this.testGenerator.setVIPwantedPatterns(this.userWantedPatterns);
    this.testGenerator.setUnwantedPatterns(this.userUnwantedPatterns);
    this.testGenerator.setNumWordInTest(wordCount);
    this.testGenerator.setMaxWordLength(letterLimit);
    this.testGenerator.setDesiredWordsHistory(this.desiredWordsHistory);
    this.testGenerator.setDesiredRandomness(desiredRandomness);
    this.testGenerator.setRemoveOutputsFromPool(removeOutputsFromPool);
    this.testGenerator.setSetAlgorithmFoundWords(setAlgorithmFoundWords);
    this.testGenerator.setSetTestStatus(this.setTestStatus);
    this.testGenerator.setWordRepetition(wordRepetition);
    console.log(this.testGenerator);
    //todo nimw: why is punctuation not being passed normally like the other variables?
    const test = this.testGenerator.getTest(punctuation);
    return test;
  }

  addTimePatternToArr(arr, text, timeToType) {
    console.log(text)
    arr.push(new TimePattern(text, timeToType));
  }

  addErrorPattern(errorPatternArr, text) {
    //console.log("addErrorPattern: text = " + text)
    let foundText = 0;
    //First we search if the pattern is already in the array
    errorPatternArr.forEach(element => {
      if (element.pattern === text) {
        element.incrErrorCount();
        foundText = 1;
      }
    });
    if (foundText) {
      return;
    }
    //If the pattern is not in the array, we add it
    let newErrorPattern = new ErrorPattern(text);
    newErrorPattern.incrErrorCount();
    errorPatternArr.push(newErrorPattern);
  }

  clearTimeArrays() {
    this.letterTTTarr = [];
    this.bigramTTTarr = [];
    this.trigramTTTarr = [];
    this.wordTTTarr = [];
    this.spacegramTTTarr = [];
  }

  clearErrorArrays() {
    this.letterErrorArr = [];
    this.bigramErrorArr = [];
    this.trigramErrorArr = [];
    this.wordErrorArr = [];
    this.spacegramErrorArr = [];
  }

  removeConsecutiveIndices(indices) {
    if (indices.length === 0) return [];

    const result = [indices[0]];

    for (let i = 1; i < indices.length; i++) {
      if (indices[i] !== indices[i - 1] + 1) {
        result.push(indices[i]);
      }
    }

    return result;
  }

  populateErrorneousPatterns() {
    //console.log("populateErrorneousLetters")
    //console.log("originalText: " + this.testMetaData.originalText)
    //console.log("error indices: " + this.testMetaData.errorIndices)
    //remove indexes from the this.errorIndices array if they come immediately after another error index
    //for example if the indexes are [1,2,3,6,7,8,10] then it should be [1,6,10]
    //This is to avoid trailing errors by the user
    this.testMetaData.errorIndices = this.removeConsecutiveIndices(this.testMetaData.errorIndices);
    //console.log("error indices: " + this.testMetaData.errorIndices)
    const originalTextArr = Array.from(this.testMetaData.originalText);
    const errorIndicesArray = this.testMetaData.errorIndices || [];
    const textLen = originalTextArr.length;
    //console.log("errorIndicesArray: " + errorIndicesArray)
    //console.log(JSON.parse(JSON.stringify(errorIndicesArray)));

    errorIndicesArray.forEach(element => {
      //single letters and bigrams
      if (originalTextArr[element] !== ' ') {
        //letter
        if (this.desiredWeaknessPatterns.errorneousLetters)
          this.addErrorPattern(this.letterErrorArr, originalTextArr[element])
        //Bigram
        if (this.desiredWeaknessPatterns.errorneousBigrams) {
          if (element !== 0) {
            if (originalTextArr[element - 1] !== ' ') {
              this.addErrorPattern(this.bigramErrorArr, originalTextArr[element - 1] + originalTextArr[element])
            }
          }
          if (element !== textLen - 1) {
            if (originalTextArr[element + 1] !== ' ') {
              this.addErrorPattern(this.bigramErrorArr, originalTextArr[element] + originalTextArr[element + 1])
            }
          }
        }
      }
      //Trigrams
      if (this.desiredWeaknessPatterns.errorneousTrigrams) {
        if (textLen > 2) {
          const start = Math.max(0, element - 2);
          const end = Math.min(textLen - 1, element + 2);
          const slicedArr = originalTextArr.slice(start, end + 1);

          for (let i = 0; i < slicedArr.length - 2; i++) {
            if (slicedArr[i] !== ' ' && slicedArr[i + 1] !== ' ' && slicedArr[i + 2] !== ' ') {
              this.addErrorPattern(this.trigramErrorArr, slicedArr[i] + slicedArr[i + 1] + slicedArr[i + 2]);
            }
          }
        }
      }
      //Spacegrams
      //TODO nimw: there's obvious code duplication between this and trigrams
      if (this.desiredWeaknessPatterns.errorneousSpacegrams) {
        if (textLen > 2) {
          const start = Math.max(0, element - 2);
          const end = Math.min(textLen - 1, element + 2);
          const slicedArr = originalTextArr.slice(start, end + 1);

          for (let i = 0; i < slicedArr.length - 2; i++) {
            if (slicedArr[i + 1] === ' ') {
              this.addErrorPattern(this.spacegramErrorArr, slicedArr[i] + "\\s" + slicedArr[i + 2]);
            }
          }
        }
      }

      // Word errors - claude wrote this
      if (this.desiredWeaknessPatterns.errorneousWords) {
        if (/\w/.test(originalTextArr[element])) {
          let start = element;
          let end = element;
          while (start > 0 && /\w/.test(originalTextArr[start - 1])) {
            start--;
          }
          while (end < textLen - 1 && /\w/.test(originalTextArr[end + 1])) {
            end++;
          }
          let word = originalTextArr.slice(start, end + 1).join('');
          word = "\\b" + word + "\\b";
          this.addErrorPattern(this.wordErrorArr, word);
        }
      }
    });
    //Finished counting each pattern error
    //Now we count the number of appearances of each pattern in the text


    const updateErrorPatterns = (patternsArray, originalTextArr) => {
      patternsArray.forEach(element => {
        element.setAppearanceCount(this.countPatternAppearances(element.pattern, originalTextArr));
        element.calculateErrorRate();
      });
    }

    updateErrorPatterns(this.letterErrorArr, originalTextArr);
    updateErrorPatterns(this.bigramErrorArr, originalTextArr);
    updateErrorPatterns(this.trigramErrorArr, originalTextArr);
    updateErrorPatterns(this.wordErrorArr, originalTextArr);

    console.log("letterErrorArr" + JSON.parse(JSON.stringify(this.letterErrorArr)));
    console.log("bigramErrorArr" + JSON.parse(JSON.stringify(this.bigramErrorArr)));
    console.log("trigramErrorArr" + JSON.parse(JSON.stringify(this.trigramErrorArr)));
    console.log("wordErrorArr" + JSON.parse(JSON.stringify(this.wordErrorArr)));
  }

  countPatternAppearances(pattern, textArr) {
    let count = 0;
    for (let i = 0; i < textArr.length; i++) {
      if (textArr.slice(i, i + pattern.length).join('') === pattern) {
        count++;
      }
    }
    return count;
  }

  populateTimeLetters() {
    //console.log("populateTimeLetters")
    const originalText = this.testMetaData.originalText;
    const errorIndices = new Set(this.testMetaData.errorIndices);
    const timeArr = this.testMetaData.timeToType;
    // Iterate through the timeArr to calculate the differences
    for (let i = 1; i < timeArr.length - 4; i++) {
      if (errorIndices.has(i) || originalText[i] === ' ') {
        continue;
      }
      const timeDiff = timeArr[i] - timeArr[i - 1];
      const letter = originalText[i];
      // //console.log("i = " + i + ", letter = " + letter)
      this.addTimePatternToArr(this.letterTTTarr, letter, timeDiff);
    }
    console.log(JSON.parse(JSON.stringify(this.letterTTTarr)));
  }

  populateTimeBigrams() {
    console.log("populateTimeBigrams")
    const originalText = this.testMetaData.originalText;
    const errorIndices = new Set(this.testMetaData.errorIndices);
    const timeArr = this.testMetaData.timeToType;
    // Iterate through userText to find valid bigrams
    for (let i = 2; i < originalText.length; i++) {
      // Ignore indices that are marked as errors
      if (errorIndices.has(i) || errorIndices.has(i - 1)) {
        continue;
      }
      //Time it takes to type the 2 letters
      const timeDiff = timeArr[i] - timeArr[i - 2];
      const bigram = originalText[i - 1] + originalText[i];
      const formattedBigram = bigram.replace(' ', '\\b');
      this.addTimePatternToArr(this.bigramTTTarr, formattedBigram, timeDiff);
      // if (!bigram.includes(" ")) {
      //   this.addTimePatternToArr(this.bigramTTTarr, bigram, timeDiff);
      // }
    }
    console.log(JSON.parse(JSON.stringify(this.bigramTTTarr)));
  }

  populateTimeTrigrams() {
    const originalText = this.testMetaData.originalText;
    const errorIndices = new Set(this.testMetaData.errorIndices);
    const timeArr = this.testMetaData.timeToType;
    for (let i = 3; i < originalText.length; i++) {
      // Skip error indices
      if (errorIndices.has(i - 2) || errorIndices.has(i) || errorIndices.has(i - 1)) {
        continue;
      }
      const timeDiff = timeArr[i] - timeArr[i - 3];
      let trigram = originalText[i - 2] + originalText[i - 1] + originalText[i];
      // Handle spaces in trigram
      if (trigram.includes(" ")) {
        // Handle leading space
        if (trigram[0] === " ") {
          trigram = "\\b" + trigram.slice(1);
        }
        // Handle trailing space
        if (trigram[2] === " ") {
          trigram = trigram.slice(0, -1) + "\\b";
        }
        // Handle middle space (rare case)
        if (trigram[1] === " ") {
          trigram = trigram[0] + "\\s" + trigram[2];
          continue;
        }
      }
      this.addTimePatternToArr(this.trigramTTTarr, trigram, timeDiff);
    }
  }

  populateTimeSpacegrams() {
    const originalText = this.testMetaData.originalText;
    const errorIndices = new Set(this.testMetaData.errorIndices);
    const timeArr = this.testMetaData.timeToType;
    for (let i = 3; i < originalText.length; i++) {
      // Skip error indices
      if (errorIndices.has(i - 2) || errorIndices.has(i) || errorIndices.has(i - 1)) {
        continue;
      }
      const timeDiff = timeArr[i] - timeArr[i - 3];
      let trigram = originalText[i - 2] + originalText[i - 1] + originalText[i];
      // Handle spaces in trigram
      if (trigram.includes(" ")) {
        // Handle leading space
        if (trigram[0] === " ") {
          // trigram = "\\b" + trigram.slice(1);
        }
        // Handle trailing space
        if (trigram[2] === " ") {
          // trigram = trigram.slice(0, -1) + "\\b";
        }
        // Handle middle space (rare case)
        if (trigram[1] === " ") {
          trigram = trigram[0] + "\\s" + trigram[2];
          this.addTimePatternToArr(this.spacegramTTTarr, trigram, timeDiff);
        }
      }
    }
  }

  populateTimeWords() {
    console.log("populateTimeWords")
    const originalText = this.testMetaData.originalText;
    const errorIndices = new Set(this.testMetaData.errorIndices);
    const timeArr = this.testMetaData.timeToType;
    const endIndex = originalText.length - 1;
    let preSpaceIdx = -1; // Initialize preSpaceIdx to -1 outside the loop
    for (let i = 0; i <= endIndex; i++) {
      if (originalText[i] === ' ' && preSpaceIdx === -1) {
        // Found the first space
        preSpaceIdx = i;
      } else if (originalText[i] === ' ' && preSpaceIdx !== -1) {
        // Found the second space
        const postSpaceIdx = i;
        const timeDiff = timeArr[postSpaceIdx - 1] - timeArr[preSpaceIdx];
        let word = originalText.slice(preSpaceIdx + 1, postSpaceIdx);
        word = "\\b" + word + "\\b";
        //If the word contains an error, then the timing of it is irrelevant
        if (!this.wordContainsError(preSpaceIdx + 1, postSpaceIdx - 1, errorIndices)) {
          this.addTimePatternToArr(this.wordTTTarr, word, timeDiff);
        }
        preSpaceIdx = i; // Update preSpaceIdx for next word
      } else if (i === endIndex && preSpaceIdx !== -1) {
        // Handle the last word (no space after it)
        const postSpaceIdx = endIndex + 1; // Simulate a space after the last character
        const timeDiff = timeArr[postSpaceIdx - 1] - timeArr[preSpaceIdx];
        let word = originalText.slice(preSpaceIdx + 1); // Slice from preSpaceIdx+1 to the end
        word = "\\b" + word + "\\b";
        //If the word contains an error, then the timing of it is irrelevant
        if (!this.wordContainsError(preSpaceIdx + 1, endIndex, errorIndices)) {
          this.addTimePatternToArr(this.wordTTTarr, word, timeDiff);
        }
      }
    }
    console.log(JSON.parse(JSON.stringify(this.wordTTTarr)));
  }

  wordContainsError(start, end, errorIndices) {
    for (let i = start; i < end; i++) {
      if (errorIndices.has(i)) {
        return true;
      }
    }
    return false;
  }

};

// Responsible for  the selection of which weaknesses to use.
class PatternArbiter {
  constructor() {
    this.inputPatternWeakenssesAggregator = [];
    this.desiredInaccuratePatternsNum = 0;
    this.desiredSlowPatternsNum = 0;
    this.combinedSlowPatterns = [];
    this.combinedInaccuratePatterns = [];
  };

  setAggregatedPatterns(patternWeakenssesAggregator) {
    this.inputPatternWeakenssesAggregator = patternWeakenssesAggregator;
  };

  setDesiredInaccuratePatternsNum(inaccuratePatternsNum) {
    this.desiredInaccuratePatternsNum = inaccuratePatternsNum;
  };

  setDesiredSlowPatternsNum(slowPatternsNum) {
    this.desiredSlowPatternsNum = slowPatternsNum;
  };

  getArbitratedSlowPatterns() {
    return this.combinedSlowPatterns;
  }

  getArbitratedInaccuratePatterns() {
    return this.combinedInaccuratePatterns;
  }

  combineSlowPatterns() {
    if (this.inputPatternWeakenssesAggregator === null) {
      return;
    }
    this.combinedSlowPatterns = this.inputPatternWeakenssesAggregator.letterTimePatternsArr.concat(
      this.inputPatternWeakenssesAggregator.bigramTimePatternsArr,
      this.inputPatternWeakenssesAggregator.trigramTimePatternsArr,
      this.inputPatternWeakenssesAggregator.wordTimePatternsArr,
      this.inputPatternWeakenssesAggregator.spacegramTimePatternsArr
    );
  }

  combineInaccuratePatterns() {
    if (this.inputPatternWeakenssesAggregator === null) {
      return;
    }
    this.combinedInaccuratePatterns = this.inputPatternWeakenssesAggregator.letterErrorPatternsArr.concat(
      this.inputPatternWeakenssesAggregator.bigramErrorPatternsArr,
      this.inputPatternWeakenssesAggregator.trigramErrorPatternsArr,
      this.inputPatternWeakenssesAggregator.wordErrorPatternsArr,
      this.inputPatternWeakenssesAggregator.spacegramErrorPatternsArr
    );
  }

  sortSlowPatterns() {
    this.combinedSlowPatterns.sort((a, b) => a.wpm - b.wpm);
    const uniquePatternsMap = new Map();
    this.combinedSlowPatterns.forEach(pattern => {
      if (!uniquePatternsMap.has(pattern.pattern)) {
        uniquePatternsMap.set(pattern.pattern, pattern);
      }
    });
    this.combinedSlowPatterns = Array.from(uniquePatternsMap.values());
  }

  removefastPatterns() {
    this.combinedSlowPatterns = this.combinedSlowPatterns.slice(0, this.desiredSlowPatternsNum);
  }

  // Claude wrote this
  sortInaccuratePatterns() {
    // Create a Map to store unique patterns
    const uniquePatterns = new Map();

    // Combine duplicate patterns
    for (const pattern of this.combinedInaccuratePatterns) {
      if (uniquePatterns.has(pattern.pattern)) {
        // If pattern already exists, update its counts
        const existingPattern = uniquePatterns.get(pattern.pattern);
        existingPattern.errorCountInTest += pattern.errorCountInTest;
        existingPattern.appearanceCountInText += pattern.appearanceCountInText;
      } else {
        // If it's a new pattern, add it to the Map
        uniquePatterns.set(pattern.pattern, pattern);
      }
    }

    // Recalculate error rates for all patterns
    for (const pattern of uniquePatterns.values()) {
      pattern.calculateErrorRate();
    }

    // Convert Map values back to an array and sort
    this.combinedInaccuratePatterns = Array.from(uniquePatterns.values())
      .sort((a, b) => b.errorRate - a.errorRate);
  }

  // sortInaccuratePatterns() {
  //   this.combinedInaccuratePatterns.sort((a, b) => b.errorRate - a.errorRate);
  // }


  removeNotSoInaccuratePatterns() {
    this.combinedInaccuratePatterns = this.combinedInaccuratePatterns.slice(0, this.desiredInaccuratePatternsNum);
  }

  removePunctuationPatterns() {
    const punctuationRegex = /[.,#!$%^&*;:{}=\-_`~()?'"]/g;
    this.combinedInaccuratePatterns = this.removePatternsByRegex(punctuationRegex, this.combinedInaccuratePatterns);
    this.combinedSlowPatterns = this.removePatternsByRegex(punctuationRegex, this.combinedSlowPatterns);
  }

  removePatternsByRegex(regex, patternsArray) {
    return patternsArray.filter(pattern => {
      return pattern.pattern.search(regex) === -1;
    });
  }

  removeCapitalizedPatterns() {
    const capitalRegex = /[A-Z]/;
    this.combinedInaccuratePatterns = this.removePatternsByRegex(capitalRegex, this.combinedInaccuratePatterns);
    this.combinedSlowPatterns = this.removePatternsByRegex(capitalRegex, this.combinedSlowPatterns);
  }


  arbitratePatterns() {
    this.combineSlowPatterns();
    this.combineInaccuratePatterns();
    this.removePunctuationPatterns();
    // It's quite likely that the user will be slow on capital letters
    // lowercasing them will make them appear as slow patterns while 
    // they are not
    this.removeCapitalizedPatterns();
    this.sortSlowPatterns();
    this.sortInaccuratePatterns();
    this.removefastPatterns();
    this.removeNotSoInaccuratePatterns();
  }

}


//This class takes the weakness arrays with their weird little "count" and "WPM" and turns
//them to a simple array of text elements.
//Because this class basically determines the output to the UI, it can hold a history
//of the user's weaknesses and strengths, giving us something to compare with the actual UI
//boxes, and thus we can know what the user added or deleted.
//With this knowledge we can make the output of this class take that into account.
//BUT IT IS VERY ANNOYING.
class PatternsPacker {
  constructor() {
    this.currPackedPatterns = new PackedPatterns();
    this.lastCurrPackedPatterns = new PackedPatterns();
    this.desiredWeaknessHistory = 0;
    this.arbitratedSlowPatterns = [];
    this.arbitratedInaccuratePatterns = [];
    this.UIslowPatterns = [];
    this.UIinaccuratePatterns = [];
    this.packedPatterns = [];
    this.userAddedSlowPatterns = [];
    this.userAddedErrorPatterns = [];
    this.userDeletedSlowPatterns = [];
    this.userDeletedErrorPatterns = [];

    this.userAddedSlowPatternsWithHistory = [];
    this.userAddedErrorPatternsWithHistory = [];
    this.userDeletedSlowPatternsWithHistory = [];
    this.userDeletedErrorPatternsWithHistory = [];
  }

  resetAllArrays() {
    console.log("resetAllArrays")
    this.currPackedPatterns = new PackedPatterns();
    this.lastCurrPackedPatterns = new PackedPatterns();
    this.arbitratedSlowPatterns = [];
    this.arbitratedInaccuratePatterns = [];
    this.UIslowPatterns = [];
    this.UIinaccuratePatterns = [];
    this.packedPatterns = [];
    this.userAddedSlowPatterns = [];
    this.userAddedErrorPatterns = [];
    this.userDeletedSlowPatterns = [];
    this.userDeletedErrorPatterns = [];

    this.userAddedSlowPatternsWithHistory = [];
    this.userAddedErrorPatternsWithHistory = [];
    this.userDeletedSlowPatternsWithHistory = [];
    this.userDeletedErrorPatternsWithHistory = [];
  }

  setDesiredWeaknessHistory(desiredWeaknessHistory) {
    this.desiredWeaknessHistory = desiredWeaknessHistory;
  }

  setArbitratedSlowPatterns(arbitratedSlowPatterns) {
    this.arbitratedSlowPatterns = arbitratedSlowPatterns;
  }
  setArbitratedInaccuratePatterns(arbitratedInaccuratePatterns) {
    this.arbitratedInaccuratePatterns = arbitratedInaccuratePatterns;
  }

  setUIslowPatterns(slowPatterns) {
    this.UIslowPatterns = slowPatterns
  };

  setUIinaccuratePatterns(inaccuratePatterns) {
    this.UIinaccuratePatterns = inaccuratePatterns
  }

  collectUsersAdditionsAndDeletionsFromUI() {
    // A lot of code repititon but Claude's improvements were alien so I'm keeping it like this.
    // debugger;
    this.userAddedSlowPatterns = this.UIslowPatterns.filter(pattern => {
      return !this.lastCurrPackedPatterns.slowArr.includes(pattern);
    });
    //console.log("this.userAddedSlowPatterns: " + this.userAddedSlowPatterns)
    this.userAddedErrorPatterns = this.UIinaccuratePatterns.filter(pattern => {
      return !this.lastCurrPackedPatterns.errorArr.includes(pattern);
    });
    this.userDeletedSlowPatterns = this.lastCurrPackedPatterns.slowArr.filter(pattern => {
      return !this.UIslowPatterns.includes(pattern);
    });
    this.userDeletedErrorPatterns = this.lastCurrPackedPatterns.errorArr.filter(pattern => {
      return !this.UIinaccuratePatterns.includes(pattern);
    });

    this.userAddedErrorPatternsWithHistory.unshift(this.userAddedErrorPatterns);
    this.userAddedSlowPatternsWithHistory.unshift(this.userAddedSlowPatterns);
    this.userDeletedErrorPatternsWithHistory.unshift(this.userDeletedErrorPatterns);
    this.userDeletedSlowPatternsWithHistory.unshift(this.userDeletedSlowPatterns);
    if (this.userAddedErrorPatternsWithHistory.length > this.desiredWeaknessHistory) {
      this.userAddedErrorPatternsWithHistory.pop();
    }
    if (this.userAddedSlowPatternsWithHistory.length > this.desiredWeaknessHistory) {
      this.userAddedSlowPatternsWithHistory.pop();
    }
    if (this.userDeletedErrorPatternsWithHistory.length > this.desiredWeaknessHistory) {
      this.userDeletedErrorPatternsWithHistory.pop();
    }
    if (this.userDeletedSlowPatternsWithHistory.length > this.desiredWeaknessHistory) {
      this.userDeletedSlowPatternsWithHistory.pop();
    }

  }

  addUserAdditionsToCurrPackedPatterns() {
    this.currPackedPatterns.slowArr = this.currPackedPatterns.slowArr.concat(this.userAddedSlowPatternsWithHistory.flat());
    this.currPackedPatterns.errorArr = this.currPackedPatterns.errorArr.concat(this.userAddedErrorPatternsWithHistory.flat());
  }

  removeUserDeletionsFromCurrPackedPatterns() {
    this.currPackedPatterns.slowArr = this.currPackedPatterns.slowArr.filter(pattern => {
      return !this.userDeletedSlowPatternsWithHistory.flat().includes(pattern);
    });
    this.currPackedPatterns.errorArr = this.currPackedPatterns.errorArr.filter(pattern => {
      return !this.userDeletedErrorPatternsWithHistory.flat().includes(pattern);
    });
  }

  manipulateHistoryBasedOnUserAdditionsAndDeletionsFromUI() {
    //This function is called after the patterns are packed.
    //It takes the user's additions and deletions into account.
    this.collectUsersAdditionsAndDeletionsFromUI();
    this.addUserAdditionsToCurrPackedPatterns();
    this.removeUserDeletionsFromCurrPackedPatterns();
    this.lastCurrPackedPatterns = this.currPackedPatterns.clone();
  }

  packPatterns() {
    //Take the patterns that were bestowed upon us by the PatternArbiter
    //and pack them into a simple array of text elements.
    this.currPackedPatterns.errorArr = this.arbitratedInaccuratePatterns.map(element => {
      return element.pattern;
    });
    this.currPackedPatterns.slowArr = this.arbitratedSlowPatterns.map(element => {
      return element.pattern;
    });
  }

  getPackedPatterns() {
    return this.currPackedPatterns;
  }

  getReallyPackedPatterns() {
    return this.currPackedPatterns.errorArr.concat(this.currPackedPatterns.slowArr);
  }
}


class HistoryManager {

  constructor() {
    this.desiredWeaknessHistory = 0;
    this.testsAggregatedPatternsArr = [];
  }

  setDesiredWeaknessHistory(desiredWeaknessHistory) {
    this.desiredWeaknessHistory = desiredWeaknessHistory;
  }

  addTestAggregatedPatterns(currTestAggregatedPatterns) {
    this.testsAggregatedPatternsArr.push(currTestAggregatedPatterns);
  }

  removeOldPatterns() {
    while (this.testsAggregatedPatternsArr.length > this.desiredWeaknessHistory) {
      this.testsAggregatedPatternsArr.shift();
    }
  }

  //Function name seems dumb, but let me explain.
  //Aggregated just means that a single object holds the 8 arrays.
  //Combined means that previously I had an array of objects, but now I have a single object.
  //The arrays inside that object are just concatenated arrays of the previous objects.
  getLatestCombinedAggregatedWeaknesses() {
    if (this.testsAggregatedPatternsArr.length === 0) {
      return null;
    }
    //todo nimw: it's retarded that if I add a new pattern template
    //(like bigram) I need to remember to add an empty array
    //to the constructor of PatternWeakenssesAggregator.
    const result = new PatternWeakenssesAggregator([], [], [], [], [], [], [], [], [], []);
    for (const patterns of this.testsAggregatedPatternsArr) {
      for (const key in patterns) {
        if (Array.isArray(patterns[key])) {
          result[key] = result[key].concat(patterns[key]);
        }
      }
    }
    return result;
  };
}


class PatternStateSetter {
  constructor() {
    this.packedPatterns = [];
    this.slowPatterns = [];
    this.inaccuratePatterns = [];
    this.slowPatternsSetter = null;
    this.inaccuratePatternsSetter = null;
  }

  setPackedPatterns(packedPatterns) {
    this.packedPatterns = packedPatterns;
  }

  setSlowPatternsSetter(slowPatternsSetter) {
    this.slowPatternsSetter = slowPatternsSetter;
  }

  setInaccuratePatternsSetter(inaccuratePatternsSetter) {
    this.inaccuratePatternsSetter = inaccuratePatternsSetter;
  }

  setPatternsBoxes() {
    if (this.slowPatternsSetter) {
      this.slowPatternsSetter(this.packedPatterns.slowArr);
    }
    if (this.inaccuratePatternsSetter) {
      this.inaccuratePatternsSetter(this.packedPatterns.errorArr);
    }
  }
}


// This should get the wanted and unwanted patterns and select words from the word pool
// create an instance of this in the analysis class so react component TypingTest 
// can get the test from there.
//I think I want to create a base class and extend it for each language. - in the future
//for now I will do everything from scratch each invocation.
//1. get the word pool
//2. get the wanted and unwanted patterns
//3. remove unwanted patterns from the word pool
//4. set the weighted patterns
//5. select the words from the word pool using the algorithm from my previous project
//6. return the words

class TestGenerator {
  constructor() {
    this.wordPoolArr = [];
    // this.filteredWordPoolArr = [];
    this.numWordsInTest = 0;
    this.maxWordLength = 0;
    this.unwantedPatternsArr = [];
    this.wantedPatternsArr = [];
    this.VIPwantedPatterns = [];
    this.unwantedPatternsArrChanged = true;
    this.weightedPatternsArr = [];
    this.spacegrams = [];
    this.unwantedWeightedPatternsArr = [];
    this.outputWordsList = [];
    this.previouslyOutputtedWords = []; //2D array
    this.desiredWordsHistory = 0;
    this.desiredRandomness = 0;
    this.desiredRandomWords = 0;
    this.removeOutputsFromPool = true;
    this.setAlgorithmFoundWords = () => { };
    this.successfullyFoundWords = false;
    this.setTestStatus = () => { };
    this.textTosetTestStatus = "";
    this.wordRepetition = 1;
  }

  resetPreviouslyOutputtedWords() {
    this.previouslyOutputtedWords = [];
    console.log("resetPreviouslyOutputtedWords")
  }

  setSetTestStatus(setTestStatus) {
    this.setTestStatus = setTestStatus;
  };

  setSetAlgorithmFoundWords(setAlgorithmFoundWords) {
    this.setAlgorithmFoundWords = setAlgorithmFoundWords;
  }

  setDesiredWordsHistory(desiredWordsHistory) {
    this.desiredWordsHistory = desiredWordsHistory;
  }

  setDesiredRandomness(desiredRandomness) {
    this.desiredRandomness = desiredRandomness;
    this.desiredRandomWords = Math.ceil(this.numWordsInTest * desiredRandomness / 100);
  }

  setRemoveOutputsFromPool(removeOutputsFromPool) {
    this.removeOutputsFromPool = removeOutputsFromPool;
  }

  setWordPool(wordPool) {
    this.wordPoolArr = [...wordPool];
    // this.wordPoolArr = ["the", "quick", "brown", "fox", "jumps", "over", "the", "lazy", "dog", "kook", "kooky", "clammy",
    //   "clam", "krook", "blook", "shlook", "shloob", "klak", "klakky", "klam", "klammy", "klam", "klammy", "klam", "klammy",
    //   "blark", "clark", "kuck", "flux", "klux", "fraaaaap", "shluuup", "shlurp", "shlurpy", "shlurp", "shlurpy", "shlurp", "shlurpy",
    //   "shluuuu", "bramp", "corgi", "natali", "mamali", "kaka", "kamil", "kakil", "karka", "marma", "shwarma", "blarblart", "zapata",
    //   "mamamia"
    // ];
    console.log("wordPool length = ", this.wordPoolArr.length)
  }

  setWordRepetition(wordRepetition) {
    this.wordRepetition = wordRepetition;
  }


  //function that removes patterns that are not valid regexes from array:
  removeNonRegexPatterns(patternsArr) {
    return patternsArr.filter(pattern => {
      try {
        new RegExp(pattern);
        return true;
      } catch (e) {
        return false;
      }
    });
  }

  setVIPwantedPatterns(VIPwantedPatterns) {
    //remove any pattern that is not a valid regex
    VIPwantedPatterns = this.removeNonRegexPatterns(VIPwantedPatterns);
    this.VIPwantedPatterns = VIPwantedPatterns;
    console.log("VIPwantedPatterns = ", this.VIPwantedPatterns)
  }

  setWantedPatterns(wantedPatternsArr) {
    //remove any pattern that is not a valid regex
    wantedPatternsArr = this.removeNonRegexPatterns(wantedPatternsArr);
    this.wantedPatternsArr = wantedPatternsArr;
    //console.log("wantedPatternsArr = ", this.wantedPatternsArr)
  }

  setNumWordInTest(numWordInTest) {
    this.numWordsInTest = numWordInTest;
    // console.log("numWordInTest = ", numWordInTest)
  }

  setMaxWordLength(letterLimit) {
    // this.wordPoolArr = this.wordPoolArr.filter(word => word.length <= letterLimit);
    this.maxWordLength = letterLimit;
  }

  setUnwantedPatterns(unwantedPatternsArr) {
    //remove any pattern that is not a valid regex
    unwantedPatternsArr = this.removeNonRegexPatterns(unwantedPatternsArr);
    this.unwantedPatternsArr = unwantedPatternsArr;
  }

  prepareWeightedPatterns() {
    //console.log("prepareWeightedPatterns")
    if (this.wantedPatternsArr.length === 0) {
      //TODO cute idea but it makes the same words show up in the test
      //The idea is to get a good mix of all the letters
      // this.wantedPatternsArr = Array.from({ length: 26 }, (_, i) => String.fromCharCode(97 + i));
      //Perhaps I can take a random subset of the words and then apply this logic
      //and I won't get the same words over and over
    }
    this.spacegrams = this.wantedPatternsArr.filter(pattern => pattern.includes("\\s"));
    this.spacegrams = [...this.spacegrams, ...this.VIPwantedPatterns.filter(pattern => pattern.includes("\\s"))];
    //uniqufy
    this.spacegrams = [...new Set(this.spacegrams)];
    this.wantedPatternsArr = this.wantedPatternsArr.filter(pattern => !pattern.includes("\\s"));
    this.spacegrams.forEach(spacegram => {
      const parts = spacegram.split("\\s");
      if (parts.length === 2) {
        const firstLetter = parts[0];
        const secondLetter = parts[1];
        const firstBigram = firstLetter + "\\b";
        const secondBigram = "\\b" + secondLetter;
        this.wantedPatternsArr.push(firstBigram);
        this.wantedPatternsArr.push(secondBigram);
      }
    });
    //uniqufy
    this.wantedPatternsArr = [...new Set(this.wantedPatternsArr)];
    console.log("spacegrams = ", this.spacegrams);
    console.log("wantedPatternsArr = ", this.wantedPatternsArr);
    this.weightedPatternsArr = this.wantedPatternsArr.map(pattern => {
      const currCountedPattern = new WeightedPattern();
      currCountedPattern.setPattern(pattern);
      return currCountedPattern;
    });

    this.unwantedWeightedPatternsArr = this.unwantedPatternsArr.map(pattern => {
      const currCountedPattern = new WeightedPattern();
      currCountedPattern.setPattern(pattern);
      currCountedPattern.setIsUnwatedPattern(true);
      return currCountedPattern;
    });
    this.weightedPatternsArr = this.weightedPatternsArr.concat(this.unwantedWeightedPatternsArr);

    const tmpVIPwantedWeightedPatternsArr = this.VIPwantedPatterns.map(pattern => {
      const currCountedPattern = new WeightedPattern();
      currCountedPattern.setPattern(pattern);
      currCountedPattern.setCustomPositiveWeight(5);
      return currCountedPattern;
    });
    this.weightedPatternsArr = this.weightedPatternsArr.concat(tmpVIPwantedWeightedPatternsArr);
    //console.log(JSON.parse(JSON.stringify(this.weightedPatternsArr)));
  }

  // Rearranges words in the output list to create specific letter combinations across word boundaries
  // For example, if we want the letter 'e' followed by 's' across a space, this function will arrange
  // words to create sequences like "take short" where 'e' and 's' are separated by a space
  rearrangeOutputWordsListForSpacegrams() {
    const chunks = [];
    let unusedWords = [...this.outputWordsList];
    const spacegrams = [...this.spacegrams];
    console.log("Initial setup:", {
      unusedWordsCount: unusedWords.length,
      spacegramsCount: spacegrams.length,
      unusedWords,
      spacegrams
    });

    const parseSpacegram = (pattern) => {
      const matches = pattern.match(/([a-z])\\s([a-z])/);
      if (!matches) {
        console.log("Failed to parse spacegram pattern:", pattern);
        return null;
      }
      return {
        endChar: matches[1],
        startChar: matches[2]
      };
    };

    const findMatches = (endChar, startChar, words, existingChunks) => {
      console.log("Finding matches for:", {
        endChar,
        startChar,
        availableWords: words.length,
        existingChunks: existingChunks.length
      });

      const matches = {
        firstWordIdx: -1,
        secondWordIdx: -1,
        fromChunk: false
      };

      // Try finding a match in existing chunks first
      for (let i = 0; i < existingChunks.length; i++) {
        const chunk = existingChunks[i];
        if (!chunk || chunk.length === 0) continue;

        const lastWord = chunk[chunk.length - 1];
        if (lastWord && lastWord[lastWord.length - 1] === endChar) {
          matches.firstWordIdx = i;
          matches.fromChunk = true;
          console.log("Found first word in chunk:", {
            chunkIndex: i,
            word: lastWord
          });
          break;
        }
      }

      // If no chunk found, try unused words
      if (matches.firstWordIdx === -1 && words.length > 0) {
        matches.firstWordIdx = words.findIndex(word =>
          word && word[word.length - 1] === endChar
        );
        if (matches.firstWordIdx !== -1) {
          console.log("Found first word in unused words:", words[matches.firstWordIdx]);
        }
      }

      // Only look for second word if we found a first word
      if (matches.firstWordIdx !== -1 && words.length > 0) {
        matches.secondWordIdx = words.findIndex(word =>
          word && word[0] === startChar
        );
        if (matches.secondWordIdx !== -1) {
          console.log("Found second word:", words[matches.secondWordIdx]);
        } else {
          console.log("No valid second word found starting with:", startChar);
        }
      }

      return matches;
    };

    let maxAttempts = spacegrams.length * 2; // Prevent infinite loops
    let attempts = 0;

    while (spacegrams.length > 0 && attempts < maxAttempts) {
      attempts++;
      const spacegram = spacegrams[0];
      console.log(`\nProcessing spacegram (attempt ${attempts}/${maxAttempts}):`, spacegram);

      const parsed = parseSpacegram(spacegram);
      if (!parsed) {
        console.log("Skipping invalid spacegram");
        spacegrams.shift();
        continue;
      }

      const { endChar, startChar } = parsed;
      const matches = findMatches(endChar, startChar, unusedWords, chunks);

      if (matches.firstWordIdx === -1 || matches.secondWordIdx === -1) {
        console.log("No valid pair found, moving spacegram to end of queue");
        spacegrams.push(spacegrams.shift());
        continue;
      }

      // Extract matched words
      let firstElement, secondElement;
      if (matches.fromChunk) {
        firstElement = chunks.splice(matches.firstWordIdx, 1)[0];
      } else {
        const word = unusedWords.splice(matches.firstWordIdx, 1)[0];
        if (!word) {
          console.error("Failed to extract first word");
          spacegrams.shift();
          continue;
        }
        firstElement = [word];
      }

      const secondWord = unusedWords.splice(
        matches.secondWordIdx - (matches.firstWordIdx < matches.secondWordIdx ? 1 : 0),
        1
      )[0];

      if (!secondWord) {
        console.error("Failed to extract second word");
        // Put the first word back
        if (!matches.fromChunk) {
          unusedWords.push(firstElement[0]);
        } else {
          chunks.push(firstElement);
        }
        spacegrams.shift();
        continue;
      }

      secondElement = [secondWord];

      // Create new chunk
      console.log("Creating chunk:", {
        firstElement,
        secondElement
      });
      chunks.push([...firstElement, ...secondElement]);
      spacegrams.shift();
    }

    console.log("\nFinal arrangement:", {
      chunks,
      remainingUnusedWords: unusedWords,
      remainingSpacegrams: spacegrams
    });

    // Add remaining words as single-word chunks
    unusedWords.forEach(word => {
      if (word) chunks.push([word]);
    });

    // Shuffle and flatten
    chunks.sort(() => Math.random() - 0.5);
    this.outputWordsList = chunks.flat().filter(Boolean);

    // Verify output
    console.log("Final output:", {
      wordCount: this.outputWordsList.length,
      expectedCount: this.numWordsInTest,
      words: this.outputWordsList
    });

    if (this.outputWordsList.length !== this.numWordsInTest) {
      console.error("Word count mismatch!");
    }
  }

  addPunctuation() {
    // Define all punctuation types and their probabilities
    const PUNCTUATION = {
      wrapping: {
        doubleQuote: { chance: 0.027, format: text => `"${text}"` },
        singleQuote: { chance: 0.024, format: text => `'${text}'` },
        squareBrackets: { chance: 0.01, format: text => `[${text}]` },
        curlyBrackets: { chance: 0.008, format: text => `{${text}}` },
        angleBrackets: { chance: 0.006, format: text => `<${text}>` }
      },
      joining: {
        slash: { chance: 0.01, format: (text1, text2) => `${text1}/${text2}` },
        // backslash: { chance: 0.005, format: (text1, text2) => `${text1}\\${text2}` }
      },
      terminal: {
        period: { chance: 0.065, value: ".", capitalizesNext: true },
        question: { chance: 0.01, value: "?", capitalizesNext: true },
        exclamation: { chance: 0.01, value: "!", capitalizesNext: true },
        comma: { chance: 0.062, value: "," },
        hyphen: { chance: 0.015, value: " -" },
        colon: { chance: 0.0034, value: ":" },
        semicolon: { chance: 0.0032, value: ";" }
      }
    };

    // Calculate thresholds for each category
    const getThresholds = (punctuationGroup) => {
      const thresholds = new Map();
      let accumulated = 0;

      Object.entries(punctuationGroup).forEach(([key, config]) => {
        accumulated += config.chance;
        thresholds.set(key, accumulated);
      });

      return thresholds;
    };

    const wrappingThresholds = getThresholds(PUNCTUATION.wrapping);
    const joiningThresholds = getThresholds(PUNCTUATION.joining);
    const terminalThresholds = getThresholds(PUNCTUATION.terminal);

    // Helper to find punctuation based on random value
    const findPunctuation = (rand, thresholds) => {
      for (const [key, threshold] of thresholds) {
        if (rand <= threshold) return key;
      }
      return null;
    };

    let formattedWords = [...this.outputWordsList];
    let result = [];
    let shouldCapitalize = true;
    let skipNext = false;

    for (let i = 0; i < formattedWords.length; i++) {
      if (skipNext) {
        skipNext = false;
        continue;
      }

      let word = formattedWords[i];

      // Capitalize if needed
      if (shouldCapitalize) {
        word = word.charAt(0).toUpperCase() + word.slice(1);
        shouldCapitalize = false;
      }

      // Handle joining punctuation
      if (i < formattedWords.length - 1) {
        const joiningRand = Math.random();
        const joiningType = findPunctuation(joiningRand, joiningThresholds);

        if (joiningType) {
          word = PUNCTUATION.joining[joiningType].format(word, formattedWords[i + 1]);
          skipNext = true;
        }
      }

      // Apply wrapping punctuation
      const wrappingRand = Math.random();
      const wrappingType = findPunctuation(wrappingRand, wrappingThresholds);

      if (wrappingType) {
        word = PUNCTUATION.wrapping[wrappingType].format(word);
      }

      result.push(word);

      // Handle last word
      if (i === formattedWords.length - 1) {
        result[result.length - 1] += ".";
        break;
      }

      // Add terminal punctuation
      const terminalRand = Math.random();
      const terminalType = findPunctuation(terminalRand, terminalThresholds);

      if (terminalType) {
        const punctuation = PUNCTUATION.terminal[terminalType];
        result[result.length - 1] += punctuation.value;
        if (punctuation.capitalizesNext) shouldCapitalize = true;
      }
    }

    return result.join(" ");
  }

  getTest(usePunctuation = false) {
    this.successfullyFoundWords = true;
    this.outputWordsList = [];
    console.log("getTest")
    this.prepareWeightedPatterns();
    this.populateOutputWordList();
    this.rearrangeOutputWordsListForSpacegrams();

    // console.log("outputWordsList.length = ", this.outputWordsList.length)
    // console.log("numWordInTest = ", this.numWordsInTest)
    if (this.outputWordsList.length !== this.numWordsInTest) {
      console.error("There is a bug in test generation. Please try again.")
      return "We could not generate a test with the requested patterns. Please try again."
    }
    let test;
    console.log("usePunctuation = ", usePunctuation)
    if (usePunctuation) {
      test = this.addPunctuation();
    }
    else {
      test = this.outputWordsList.join(' ');
    }
    this.setAlgorithmFoundWords(this.successfullyFoundWords);
    console.log("returning test = " + test)
    console.log("successfullyFoundWords = ", this.successfullyFoundWords)
    if (this.setTestStatus) {
      this.setTestStatus(this.textTosetTestStatus)
    }
    return test;
  };

  returnAllRemovedWordsToPool() {
    console.log("returnAllRemovedWordsToPool")
    console.log("previouslyOutputtedWords = ", this.previouslyOutputtedWords)
    this.wordPoolArr = this.wordPoolArr.concat(this.previouslyOutputtedWords.flat());
    this.previouslyOutputtedWords = [];
  }

  populateOutputWordList() {
    console.log("populateOutputWordList numWordsInTest = " + this.numWordsInTest)
    this.assertArraysNotEmpty();
    this.handleWordPool();
    this.generateOutputList();
    this.updateTestStatus();
    this.finalizeOutputList();
  }

  assertArraysNotEmpty() {
    if (this.wordPoolArr.length === 0) {
      alert("Word pool or weighted patterns array is empty");
      throw new Error("Word pool or weighted patterns array is empty");
    }
  }

  handleWordPool() {
    if (!this.removeOutputsFromPool) return;

    console.log("previousOutputtedWords.length = ", this.previouslyOutputtedWords.length);
    console.log("desiredWordsHistory = ", this.desiredWordsHistory);
    console.log("wordPoolArr.length = ", this.wordPoolArr.length);

    this.removeUsedWordsFromPool();
    this.restoreOldWordsToPool();


    console.log("previousOutputtedWords.length = ", this.previouslyOutputtedWords.length);
    console.log("desiredWordsHistory = ", this.desiredWordsHistory);
    console.log("wordPoolArr.length = ", this.wordPoolArr.length);
  }

  removeUsedWordsFromPool() {
    // Create a map to count how many times each word appears in previousWords
    const previousWords = this.previouslyOutputtedWords.flat();
    console.log("Removing words from pool: " + previousWords);
    const wordRemovalCount = new Map();
    previousWords.forEach(word => {
      wordRemovalCount.set(word, (wordRemovalCount.get(word) || 0) + 1);
    });

    // Remove exactly the number of instances that were used
    const newWordPool = [...this.wordPoolArr];
    wordRemovalCount.forEach((count, word) => {
      // Find the first occurrence of this word and remove it, repeat 'count' times
      for (let i = 0; i < count; i++) {
        const index = newWordPool.indexOf(word);
        if (index !== -1) {
          newWordPool.splice(index, 1);
        }
      }
    });

    this.wordPoolArr = newWordPool;
  }

  restoreOldWordsToPool() {
    while (
      this.previouslyOutputtedWords.length > this.desiredWordsHistory &&
      this.previouslyOutputtedWords.length > 0
    ) {
      const oldestOutputWordsList = [...this.previouslyOutputtedWords.pop()];
      console.log("Putting words back into pool: " + oldestOutputWordsList);
      this.wordPoolArr = this.wordPoolArr.concat([...oldestOutputWordsList]);
    }
  }

  generateOutputList() {
    let counter = 0;
    let numNonRandomWords = this.numWordsInTest - this.desiredRandomWords;

    while (this.outputWordsList.length < this.numWordsInTest) {
      if (counter === numNonRandomWords) {
        //The reason I arbitrarily decided that "random" should take the unwanted patterns
        //into account, but not the wanted patterns, is because my personal use of the
        //unwanted patterns, is that my hand sometimes hurts when typing 't', 'r', 'b'
        //so I really really don't want them, not at some percentage
        // console.log("counter = " + counter + " this.desiredRandomWords = " + this.desiredRandomWords);
        this.weightedPatternsArr = this.unwantedWeightedPatternsArr;
      }

      this.addWordWithRandomScoring();
      counter += 1;
    }
  }

  addWordWithRandomScoring() {
    //This is the important part of the function
    //use altGetWordScore or getWordScore in probability of 50%
    if (Math.random() < 0.5) {
      this.addNewWordToOutputList(this.getWordScore.bind(this));
    } else {
      this.addNewWordToOutputList(this.altGetWordScore.bind(this));
    }
  }

  updateTestStatus() {
    const numRandomWords = this.calculateRandomWordsCount();
    const numPatternWords = this.numWordsInTest - numRandomWords;

    this.textTosetTestStatus = this.formatTestStatus(numRandomWords, numPatternWords);
    console.log(this.textTosetTestStatus);
  }

  calculateRandomWordsCount() {
    if (this.wantedPatternsArr.length === 0 && this.VIPwantedPatterns.length === 0) {
      return this.numWordsInTest;
    }
    return this.desiredRandomWords;
  }

  formatTestStatus(numRandomWords, numPatternWords) {
    return `${this.numWordsInTest} words were generated. ` +
      `${numRandomWords} random word${numRandomWords === 1 ? '' : 's'}.` +
      ` ${numPatternWords} word${numPatternWords === 1 ? ' is' : 's are'} based on the patterns.` +
      ` ${this.wantedPatternsArr.length + this.VIPwantedPatterns.length} wanted pattern${this.wantedPatternsArr.length + this.VIPwantedPatterns.length === 1 ? '' : 's'} and ${this.unwantedPatternsArr.length} unwanted pattern${this.unwantedPatternsArr.length === 1 ? '' : 's'} were used.`;
  }

  finalizeOutputList() {
    //shuffle the outputWordsList before returning
    this.outputWordsList.sort(() => Math.random() - 0.5);
    console.log(JSON.parse(JSON.stringify(this.outputWordsList)));

    //Get rid of the test words for a while so we don't get the same words in the next few tests
    this.previouslyOutputtedWords.unshift([...this.outputWordsList]);

    //restore the word pool if not words were supposed to be removed
    if (!this.removeOutputsFromPool) {
      //completely reset the word pool and the previously outputted words
      this.wordPoolArr = this.wordPoolArr.concat(this.previouslyOutputtedWords.flat());
      this.previouslyOutputtedWords = [];
    }
  }

  addNewWordToOutputList(getWordScore) {
    this.recalculatePatternsWeight();
    let newWord = "";
    //What's "bind"?
    newWord = this.findNewBestWordToAddToList(getWordScore);
    console.log(`newWord = ${newWord}, getWordScore = ${getWordScore(newWord)}`);
    this.incrementPatternsCounters(newWord);
    this.outputWordsList.push(newWord);
    //Remove the word from the word pool so it's not the only word that shows up
    this.removeWordFromInputList(newWord);
  }

  incrementPatternsCounters(newWord) {
    for (const pattern of this.weightedPatternsArr) {
      if (newWord.includes(pattern.getPattern())) {
        pattern.incrementCounter();
      }
    }
  }

  recalculatePatternsWeight() {
    let totalCount = 0;
    for (const pattern of this.weightedPatternsArr) {
      totalCount += pattern.getCounterValue();
    }
    // console.log(this.constructor.name, ": calculatePatternWeight : totalCount = ", totalCount);
    for (const pattern of this.weightedPatternsArr) {
      pattern.calculateWeight(totalCount);
    }
    //console.log(JSON.parse(JSON.stringify(this.weightedPatternsArr)));
  }

  findNewBestWordToAddToList(getWordScore) {
    // console.log(this.constructor.name, "Finding best word");
    let highestScoreWordLength = Infinity; // Initialize to Infinity
    let highestScore = 0;
    let lowestScore = 0;
    let currWordLength = 0;
    let currScore = 0;
    let wordToReturn = "";

    if (this.wordPoolArr.length === 0) {
      this.returnAllRemovedWordsToPool();
    }

    let bestWords = [];  // Array to collect all words tied for highest score

    for (const word of this.wordPoolArr) {
      currWordLength = word.length;
      if (currWordLength > this.maxWordLength) {
        currScore = 0;
      } else {
        currScore = getWordScore(word);
      }

      // If we found a better score, clear the array and start fresh
      if (currScore > highestScore) {
        highestScore = currScore;
        highestScoreWordLength = currWordLength;
        bestWords = [word];
      }
      // If score is equal, consider word length
      else if (currScore === highestScore && highestScore !== 0) {
        if (currWordLength < highestScoreWordLength) {
          // If word is shorter, clear array and start fresh with this length
          highestScoreWordLength = currWordLength;
          bestWords = [word];
        }
        else if (currWordLength === highestScoreWordLength) {
          // If score and length are equal, add to candidates
          bestWords.push(word);
        }
      }

      if (currScore < lowestScore) {
        lowestScore = currScore;
      }
    }

    // Randomly select from best words if we found any
    wordToReturn = bestWords.length > 0
      ? bestWords[Math.floor(Math.random() * bestWords.length)]
      : "";

    if (highestScore === 0) {
      const wordsWithZeroScore = this.wordPoolArr.filter(word => getWordScore(word) === 0);
      if (wordsWithZeroScore.length > 0) {
        const randomIndex = Math.floor(Math.random() * wordsWithZeroScore.length);
        wordToReturn = wordsWithZeroScore[randomIndex];
      }
    }

    //This should really only happen if the user did something terrible 
    //with the "exclude" patterns. I could still return a random word
    //but for now I prefer this solution
    if (wordToReturn === "") {
      this.successfullyFoundWords = false;
      return "BadParams";
    }
    // console.log(this.constructor.name, "Word to return: ", wordToReturn, "Score: ", highestScore);
    return wordToReturn;
  }

  //This function prefers short words
  altGetWordScore(word) {
    let wordScore = 0;
    for (const pattern of this.weightedPatternsArr) {
      const patternRegex = new RegExp(pattern.getPattern(), 'g');
      const occurrences = (word.match(patternRegex) || []).length;
      wordScore += pattern.getWeight() * occurrences;
    }
    wordScore = wordScore / word.length;
    // console.log(this.constructor.name, "Word: ", word, "Score: ", wordScore, "Length: ", word.length);
    return wordScore;
  }


  //This function prefers long words
  getWordScore(word) {
    let wordScore = 0;
    for (const pattern of this.weightedPatternsArr) {
      //Turn the string to a regex object
      const patternRegex = new RegExp(pattern.getPattern(), 'g');
      //Check how many times the pattern appears in the word
      const occurrences = (word.match(patternRegex) || []).length;
      //Add the weight of the pattern to the score 
      wordScore += pattern.getWeight() * occurrences;
    }
    // //console.log(this.constructor.name, "Word: ", word, "Score: ", wordScore);
    return wordScore;
  }

  removeWordFromInputList(newWord) {
    // console.log(this.constructor.name, "Removing word '", newWord, "' from wordPoolArr");
    const index = this.wordPoolArr.indexOf(newWord);
    if (index !== -1) {
      this.wordPoolArr.splice(index, 1);
    }
  }

}



class WeightedPattern {
  constructor() {
    this.pattern = "";
    this.counter = 0;
    this.weight = 0.0;
    this.isUnwatedPattern = false;
    this.customPositiveWeight = 0;
  }

  setIsUnwatedPattern(isUnwatedPattern) {
    this.isUnwatedPattern = isUnwatedPattern;
  }

  setCustomPositiveWeight(customPositiveWeight) {
    this.customPositiveWeight = customPositiveWeight;
  }

  setPattern(pattern) {
    this.pattern = pattern;
    //TODO I want to work with regexes
    // this.pattern = new RegExp(pattern);
  }

  getPattern() {
    return this.pattern;
  }

  incrementCounter() {
    this.counter += 1;
  }

  getCounterValue() {
    return this.counter;
  }

  calculateWeight(aggregatedCounters) {
    if (this.isUnwatedPattern) {
      if (aggregatedCounters !== 0) {
        this.weight = -20 - this.counter / aggregatedCounters;
      } else {
        this.weight = -20;
      }
    }
    else {
      if (aggregatedCounters !== 0) {
        this.weight = 1.001 - (this.counter / aggregatedCounters);
      } else {
        this.weight = 1;
      }
      this.weight += this.customPositiveWeight;
    }
  }

  getWeight() {
    return this.weight;
  }
}

export { SingleTestAnalysis, Metadata, DesiredWeaknessPatterns, HistoryManager, PatternsPacker };
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { debounce } from 'lodash'; // Make sure to import lodash or implement your own debounce function
import { Paper, Box, CircularProgress, Stack, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Metadata, DesiredWeaknessPatterns, HistoryManager, PatternsPacker } from '../utils/mainAlgorithm';
// import { checkIfNewUserRecord } from '../utils/userRecords.js';
import { wordCounts } from '../utils/defaultValues';
import { updatePersonalBest } from '../utils/firestoreUtils';
//TODO fix the lists with this - pretty awesome
// https://www.online-spellcheck.com/result/c32e687b3b200dab09833861234394569d624ab9

import styles from './TypingTest.module.css'
import { TTDialog } from './TTDialog'
import { TypingTip } from './TypingTip'
import { MemoizedWord,  } from './Word'
import { Caret } from './Caret'
import SkeletonStack from './SkeletonStack'
import { CapsLockWarning } from './CapsLockWarning'
import  TypingDisplay from './typing/displays/TypingDisplay.jsx'
import { DisplayMode } from './typing/displays/TypingDisplay.jsx';

import { MainContentContext } from '../contexts/MainContentContext'
import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx'
import { getTestConfiguration} from '../contexts/SuperMainContext.jsx'
import { AppContext } from '../contexts/AppContext.jsx'

const SHARED_PUNCTUATION = '[ @#$%^&*()+\\-_=<>?/\\\\|{}\\[\\]~`!,.\'":;]';

const validCharacters = {
  Arabic: new RegExp(`^([ء-ي]|\\d|${SHARED_PUNCTUATION})$`),
  Chinese: /^.$/,  // Keep as-is since it needs to accept any character
  English: new RegExp(`^([a-zA-Z]|\\d|${SHARED_PUNCTUATION})$`),
  French: new RegExp(`^([a-zA-ZàâçéèêëîïôûùüÿñæœÀÂÇÉÈÊËÎÏÔÛÙÜŸŒ]|\\d|${SHARED_PUNCTUATION})$`),
  German: new RegExp(`^([a-zA-ZäöüÄÖÜß]|\\d|${SHARED_PUNCTUATION})$`),
  Hebrew: new RegExp(`^([א-ת]|\\d|${SHARED_PUNCTUATION})$`),
  Italian: new RegExp(`^([a-zA-ZàèéìíîòóùúÀÈÉÌÍÎÒÓÙÚ]|\\d|${SHARED_PUNCTUATION})$`),
  Portuguese: new RegExp(`^([a-zA-ZáâãàéêíóôõúçÁÂÃÀÉÊÍÓÔÕÚÇ]|\\d|${SHARED_PUNCTUATION})$`),
  Russian: new RegExp(`^([а-яА-ЯёЁ]|\\d|${SHARED_PUNCTUATION})$`),
  Spanish: new RegExp(`^([a-zA-ZáéíóúñÁÉÍÓÚÑ]|\\d|${SHARED_PUNCTUATION})$`),
  Numbers: new RegExp(`^(\\d|${SHARED_PUNCTUATION})$`),
  'Special Characters': new RegExp(`^${SHARED_PUNCTUATION}$`),
  Emojis:  /^.$/,  // Keep as-is since it needs to accept any character
};

export const isRTL = (language) => ['Hebrew', 'Arabic'].includes(language);

const TypingTest = ({ onStart, onEnd }) => {
  //Element N in timeToType would be the time it took to reach the Nth
  //character in the typing test, relative to the 0th character
  const metadataRef = useRef(new Metadata());
  const testContainerRef = useRef(null);
  const inputRef = useRef(null);
  const testStart = useRef(true);
  const currWordCount = useRef(0);

  // const [typingTestText, setTypingTestText] = useState('');
  const [userInput, setUserInput] = useState('');
  const [isCapslockOn, setIsCapslockOn] = useState(false);
  //Claude instructions:
  //don't worry about putting this state variable in the context
  //I'm going to change it manually in the code right here until
  //The displays are all working, then I'll move it to the context 
  //by myself.
  const [displayMode, setDisplayMode] = useState(DisplayMode.STATIC);
  const { slowPatternTemplates, inaccuratePatternTemplates, setWPM,
    setAccuracy, setInaccuratePatterns, setSlowPatterns, slowPatterns,
    inaccuratePatterns, desiredWeaknessHistory, desiredRefreshInterval, testCount,
    setTestCount, inaccuratePatternsNum, slowPatternsNum, freeze,
     desiredWordsHistory, statistics, setStatistics,
    resetProgress, setResetProgress, clearWeaknessPatterns, showSessionSummary,
    setShowSessionSummary, setSessionWPMscores
  } = useContext(MainContentContext);

  const { selectedTestLength, selectedWordList, selectedLanguage,
    includedPatterns, excludedPatterns, testAnalysisRef, typingTestText,
    setTypingTestText, desiredRandomness, getRandomTest,
    isFullyRandom, setAlgorithmFoundWords, letterLimit,
    isLoading, setIsLoading, randomPhase, setShowNewRecord,
    punctuation, randomDuration, wordRepetition
  } = useContext(SuperMainContentContext)

  const { isFocused, setIsFocused, currUser } = useContext(AppContext);

  const [typingTipDialog, setTypingTipDialog] = useState(false);
  const [keyStates, setKeyStates] = useState({});
  const [isInputLocked, setIsInputLocked] = useState(false);
  const { isTestActive, setIsTestActive } = useContext(MainContentContext);
  const theme = useTheme();
  const handleOpentypingTipDialog = () => setTypingTipDialog(true);
  const handleClosetypingTipDialog = () => setTypingTipDialog(false);
  const [caretPosition, setCaretPosition] = useState(null);
  const [isCursorVisible, setIsCursorVisible] = useState(true);
  const [shouldBlinkCaret, setShouldBlinkCaret] = useState(true);
  const [contentHeight, setContentHeight] = useState(0);
  const isTyping = useRef(false);

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (!isLoading && testContainerRef.current) {
      const letters = testContainerRef.current.querySelectorAll('[data-index]');
      if (letters.length) {
        const firstRect = letters[0].getBoundingClientRect();
        const lastRect = letters[letters.length - 1].getBoundingClientRect();
        const height = lastRect.bottom - firstRect.top;
        setContentHeight(height);
      }
    }
  }, [typingTestText, isLoading]);

  useEffect(() => {
    if (isFocused) {
      focusInput();
    }
    // console.log("isFocused = ", isFocused)
  }, [isFocused]);

  useEffect(() => {
    const handleMouseMove = () => {
      if (isTestActive) {
        setIsCursorVisible(true);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    //useEffect 'cleanup' function
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isTestActive]);

  useEffect(() => {
    if (isTestActive) {
      setIsCursorVisible(false);
    } else {
      setIsCursorVisible(true);
    }
  }, [isTestActive]);

  useEffect(() => {
    if (isCursorVisible) {
      document.body.style.cursor = 'auto';
    } else {
      document.body.style.cursor = 'none';
    }

    return () => {
      document.body.style.cursor = 'auto';
    };
  }, [isCursorVisible]);

  const updateCaretPosition = useCallback(() => {
    const currentLetter = document.querySelector(`[data-index="${userInput.length}"]`);
    if (currentLetter && testContainerRef.current) {
      const containerRect = testContainerRef.current.getBoundingClientRect();
      const letterRect = currentLetter.getBoundingClientRect();
      setCaretPosition({
        left: letterRect.left - containerRect.left,
        top: letterRect.top - containerRect.top,
        width: letterRect.width,
        height: letterRect.height
      });
    }
  }, [userInput.length]);

  useEffect(() => {
    updateCaretPosition();
    window.addEventListener('resize', updateCaretPosition);
    return () => window.removeEventListener('resize', updateCaretPosition);
  }, [updateCaretPosition]);

  useEffect(() => {
    updateCaretPosition();
  }, [typingTestText]);

  const debouncedUpdateCaretPosition = useCallback(
    debounce(updateCaretPosition, 16), // Debounce to roughly 60fps
    [updateCaretPosition]
  );

  useEffect(() => {
    if (!isLoading && isFocused) {
      debouncedUpdateCaretPosition();
    }
  }, [userInput, isLoading, isFocused, debouncedUpdateCaretPosition]);

  useEffect(() => {
    if (resetProgress) {
      clearWeaknessPatterns();
      testAnalysisRef.current.patternPacker.resetAllArrays();
      testAnalysisRef.current.clearHistory();
      testAnalysisRef.current.testGenerator.resetPreviouslyOutputtedWords();
      setSessionWPMscores([]);
      setResetProgress(false);
      resetTest(true)
      setWPM(0);
    }
  }, [resetProgress]);

  useEffect(() => {
    // console.log("TypingTest 1st mount useEffect")
    const fetchTextAndFocus = async () => {
      //TODO why did I need this here?
      await resetTest();
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    const handleWindowFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
        setIsFocused(true);
      }
    };

    fetchTextAndFocus();
    window.addEventListener('focus', handleWindowFocus);

    return () => {
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, []);



  //A claude callback
  useEffect(() => {
    const preventDefault = (e) => {
      e.preventDefault();
    };
    if (isTestActive) {
      // window.addEventListener('wheel', preventDefault, { passive: false });
      window.addEventListener('touchmove', preventDefault, { passive: false });
    }

    return () => {
      window.removeEventListener('wheel', preventDefault);
      window.removeEventListener('touchmove', preventDefault);
    };
  }, [isTestActive]);


  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const scrollTestToCenter = () => {
    if (!testContainerRef.current) return;
  
    // Get the test container's bounding rect
    const testRect = testContainerRef.current.getBoundingClientRect();
  
    // Get viewport height
    const viewportHeight = window.innerHeight;
  
    // Calculate the ideal scroll position that would center the test
    const currentScrollY = window.scrollY;
    const testTop = currentScrollY + testRect.top;
    const testHeight = testRect.height;
  
    // Calculate where we want the test's top edge to be
    const idealTop = Math.max(
      0, // Don't scroll past the top of the page
      currentScrollY + (viewportHeight - testHeight) / 2
    );
  
    // Calculate how far we need to scroll
    const scrollAmount = testTop - idealTop;
  
    // Scroll smoothly
    window.scrollTo({
      top: currentScrollY + scrollAmount,
      behavior: 'smooth'
    });
  };

  const handleMousePress = (event) => {
    // console.log("handleMousePress")
    if (!isFocused) {
      inputRef.current.focus();
      setIsFocused(true);
    }
  };

  //TODO nimw : I think this is being called even when the typing test is already focused
  const handleBlur = (e) => {
    // console.log("handleBlur")
    //This didn't work
    // if (e.relatedTarget && e.relatedTarget.closest('.typingTest')) {
    //   e.preventDefault(); // Prevent blur if clicked within the same component
    //   return;
    // }
    setIsFocused(false);
  };

  // Function to check if a character is valid based on the language
  const isValidCharacter = (pressedKey, language = 'English') => {
    const regex = validCharacters[language] || validCharacters['English'];
    return regex.test(pressedKey);
  };

  const startOfTest = () => {
    // console.log('Start of test');
    metadataRef.current.originalText = typingTestText;
    metadataRef.current.startTime = Date.now();
    setIsTestActive(true);
    setShouldBlinkCaret(false);
    onStart();
    requestAnimationFrame(() => {
      // scrollToTop();
      scrollTestToCenter();
      console.log('Start of test');
    });
  };

  const prepareDesiredPatterns = (desiredPatterns, pressedKey) => {
    console.log("End of test");
    if (userInput.length > 0) {
      metadataRef.current.userText = userInput + pressedKey;
    }
    console.log(JSON.parse(JSON.stringify(metadataRef.current)))
    setTestCount(prevTestCount => prevTestCount + 1);

    console.log(`Test count: ${testCount + 1}, desiredRefreshInterval: ${desiredRefreshInterval}, randomDuration: ${randomDuration}`);

    //TODO nimw: why is this here and not in ProgressBar component?
    // if (((testCount + 1) % desiredRefreshInterval) < randomDuration) {
    //   randomPhase.current = true;
    // }
    // else {
    //   randomPhase.current = false;
    // }

    desiredPatterns.slowLetters = slowPatternTemplates.includes("Letters");
    desiredPatterns.slowBigrams = slowPatternTemplates.includes("Bigrams");
    desiredPatterns.slowTrigrams = slowPatternTemplates.includes("Trigrams");
    desiredPatterns.slowWords = slowPatternTemplates.includes("Words");
    desiredPatterns.slowSpacegrams = slowPatternTemplates.includes("Spacegrams");
    desiredPatterns.errorneousLetters = inaccuratePatternTemplates.includes("Letters");
    desiredPatterns.errorneousBigrams = inaccuratePatternTemplates.includes("Bigrams");
    desiredPatterns.errorneousTrigrams = inaccuratePatternTemplates.includes("Trigrams");
    desiredPatterns.errorneousWords = inaccuratePatternTemplates.includes("Words");
    desiredPatterns.errorneousSpacegrams = inaccuratePatternTemplates.includes("Spacegrams");
    console.log(JSON.parse(JSON.stringify(desiredPatterns)))
  }

  const endOfTest = async (pressedKey) => {
    let desiredPatterns = new DesiredWeaknessPatterns();
    prepareDesiredPatterns(desiredPatterns, pressedKey);
    const { WPM, accuracy } =
      testAnalysisRef.current.getPerformanceMetrics(metadataRef.current, desiredPatterns,
        desiredWeaknessHistory, setSlowPatterns, setInaccuratePatterns,
        slowPatterns, inaccuratePatterns, includedPatterns, excludedPatterns,
        inaccuratePatternsNum, slowPatternsNum, freeze, desiredWordsHistory);
    setWPM(WPM);
    if (isFullyRandom.current) {
      checkIfNewUserRecord(accuracy, WPM, selectedTestLength, selectedWordList, selectedLanguage);
    }
    setAccuracy(accuracy);
    setIsTestActive(false);
    //TODO nimw: figure out if this is needed.
    // It was added here to allow time for the statistics table to be updated
    //but it's not really making any sense
    setTimeout(() => {
      resetTest();
    }, 100);
  };

  const checkIfNewUserRecord = (accuracy, WPM, selectedTestLength, selectedWordList, selectedLanguage) => {
    const currentBest = statistics[selectedLanguage]?.[selectedWordList]?.[selectedTestLength]?.value ?? 0;
    console.log('Test completed, checking for new record:', {
      accuracy,
      WPM,
      currentBest,
      selectedTestLength,
      selectedWordList,
      selectedLanguage
    });
    
    if (WPM > currentBest) {
      if (accuracy > 70) {
        console.log('New personal best:', WPM);
        setShowNewRecord(true);
        updateUserPersonalBest(WPM, selectedTestLength, selectedWordList, selectedLanguage);
      
        setStatistics(prevStats => ({
          ...prevStats,
          [selectedLanguage]: {
            ...prevStats[selectedLanguage],
            [selectedWordList]: {
              ...prevStats[selectedLanguage]?.[selectedWordList],
              [selectedTestLength]: {
                value: WPM,
                achieved_at: new Date()
              }
            }
          }
        }));
      }
      else {
        console.log('Accuracy too low for new record:', accuracy);
        alert(`Accuracy ${accuracy}% is too low for a personal best. Accuracy must be above 70% to set a new record.`);
      }
    }
  };


  const updateUserPersonalBest = async (wpm, testLength, wordList, language) => {
    if (currUser) {
      try {
        await updatePersonalBest(
          currUser.uid,
          language,
          wordList,
          testLength,
          wpm
        );
      } catch (error) {
        console.error('Error updating personal best:', error);
      }
    }
  };

  useEffect(() => {
    currWordCount.current = wordCounts[selectedTestLength];
  }, [selectedTestLength]);

  const resetTest = async (refetchText = true) => {
    console.log("resetTest")
    setIsLoading(true);
    await new Promise(resolve => setTimeout(resolve, 0));

    try {
      let fetchedText = '';
      if (refetchText) {
        if (((testCount + 1) % desiredRefreshInterval === 0) && testCount !== 0 && !freeze) {
          setInaccuratePatterns([]);
          setSlowPatterns([]);
          // getRandomTest();
          testAnalysisRef.current.historyManager = new HistoryManager();
          testAnalysisRef.current.testGenerator.returnAllRemovedWordsToPool();
          testAnalysisRef.current.patternsPacker = new PatternsPacker();
        }
        // else {
          if (randomPhase.current) {
            console.log("Random Analysis")
            getRandomTest();
          }
          else {
            console.log("Targeted Practice")
            // Extract the testDuration and wordList from the getTestConfiguration function
            const { wordCount, wordList } = getTestConfiguration(selectedTestLength, selectedWordList, selectedLanguage);
            // Pass the extracted values to getNextTest
            fetchedText = await testAnalysisRef.current.getNextTest(
              wordCount,
              wordList,
              desiredRandomness,
              true,
              setAlgorithmFoundWords,
              letterLimit,
              punctuation,
              wordRepetition
            );
            setTypingTestText(fetchedText);
          }
        // }
      }
      setUserInput('');
      testStart.current = true;
      metadataRef.current = {
        originalText: '',
        userText: '',
        errorIndices: [],
        startTime: 0,
        timeToType: []
      };
      onEnd();
    } catch (error) {
      console.error("Error resetting test:", error);
      alert("Error resetting test, please contact us with steps to reproduce this error 🙏");
      // Handle the error appropriately, maybe set an error state or show a message to the user
    } finally {
      setIsLoading(false);
    }
    setIsTestActive(false);
    setShouldBlinkCaret(true);
    // setIsFocused(true);
  };



  const handlePressedInput = (pressedKey) => {
    if (userInput.length <= typingTestText.length) {
      setUserInput(prevInput => prevInput + pressedKey);
      if (pressedKey !== typingTestText[userInput.length]) {
        console.log("wrong key")
        metadataRef.current.errorIndices.push(userInput.length);
      }
      // Record the current timestamp relative to the start time
      const elapsedTime = Date.now() - metadataRef.current.startTime;
      metadataRef.current.timeToType.push(elapsedTime);
    }
  };

  const handleInput = (pressedKey) => {
    if (isInputLocked) return;

    if (userInput.length === 0 && testStart.current === true) {
      testStart.current = false;
      startOfTest();
    }
    handlePressedInput(pressedKey);

    if (isTestActive && !isCursorVisible) {
      setIsCursorVisible(false);
    }

    if (userInput.length + 1 === typingTestText.length) {
      setIsInputLocked(true);
      setTimeout(() => {
        endOfTest(pressedKey);
        setIsInputLocked(false);
      }, 50);
    }
  };

  const isBackspace = (pressedKey) => pressedKey === 'Backspace';

  const handleBackspace = () => {
    setUserInput(prevInput => prevInput.slice(0, -1));
    metadataRef.current.timeToType.pop();
  };

  const isCtrlBackspace = (event) => (event.ctrlKey || event.altKey) && event.key === 'Backspace';

  const handleCtrlBackspace = () => {
    console.log("ctrl backspace");
    if (userInput.length > 0) {
      let startIndex = userInput.length - 1;
      // while (startIndex > 0 && typingTestText[startIndex - 1] !== ' ') {
      while (startIndex > 0 && ![' ', '.', ',', ';', ':', '!', '?', '"', "'", "\\", "/", '}', ')', ']'].includes(typingTestText[startIndex - 1])) {
        startIndex--;
      }
      console.log("index = ", startIndex);

      // Calculate the number of backspaces needed
      const numBackspaces = userInput.length - startIndex;
    
      // Call handleBackspace the required number of times
      for (let i = 0; i < numBackspaces; i++) {
        handleBackspace();
      }
    }
  };


  const isEsc = (event) => event.key === 'Escape';
  const isTab = (event) => event.key === 'Tab';

  const handleEsc = (event) => {
    console.log('handleEsc')
    event.preventDefault(); //To prevent tab from navigating
    event.stopPropagation(); // I don't remember why I added this
    resetTest(false);
  }


  useEffect(() => {
    const handleKeyUp = (event) => {
      const capsLockState = event.getModifierState('CapsLock');
      if (capsLockState !== isCapslockOn) {
        setIsCapslockOn(capsLockState);
      }
    };

    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [isCapslockOn]);

  const handleKeyDown = (event) => {

    const capsLockState = event.getModifierState('CapsLock');
  
    // Update state if it's different from current state
    if (capsLockState !== isCapslockOn) {
      setIsCapslockOn(capsLockState);
    }
    const pressedKey = event.key;

    //This isn't related to "language", don't be fooled
    if ((event.ctrlKey || event.metaKey) && pressedKey.length === 1 && pressedKey.match(/[a-zA-Z0-9-!@#$%^&*()+]/i)) {
      // Do nothing for ctrl+<letter>
      return;
    }

    if (isBackspace(pressedKey)) {
      if (isCtrlBackspace(event)) {
        handleCtrlBackspace();
      } else {
        handleBackspace();
      }
    }
    // else if (!keyStates[pressedKey]) {
    // Prevent spacebar scrolling when focused
    if (pressedKey === ' ' && isFocused) {
      event.preventDefault();
    }
    if (isValidCharacter(pressedKey, selectedLanguage)) {
      // if (true) {
      handleInput(pressedKey);
    }
    else if (isEsc(event) || isTab(event)) {
      handleEsc(event);
    }
    //TODO figure out how to add the keystates to not allow the user to hold down the key
    //without interfering with capital letters usint shift key
    // setKeyStates((prevStates) => ({ ...prevStates, [pressedKey]: true }));
    // }
    if (isTestActive && isValidCharacter(event.key)) {
      setIsCursorVisible(false);
      isTyping.current = true;
    }
  };

  const handleKeyUp = (event) => {
    //TODO nimw: figure out why I had this function here and document.
    //We don't delete functions, we document them and stop using them
    // const { key } = event;
    // setKeyStates((prevStates) => ({ ...prevStates, [key]: false }));
    isTyping.current = false;
  };

  const setCorrectness = (index) => {
    if (index < userInput.length && userInput[index] === typingTestText[index]) {
      if (metadataRef.current.errorIndices.includes(index)) {
        return "corrected";
      }
      return "correct";
    } else {
      return "incorrect";
    }
  };

  //Claude instructions:
  //You might need to change this function to accommodate SlidingDisplay
const renderStyledText = () => {
  if (typingTestText.length === 0) {
    return null;
  }
  const wordsWithSpaces = typingTestText.match(/\S+\s*/g) || [];
  let startIndex = 0;

  const content = wordsWithSpaces.map((word, index) => {
    const wordComponent = (
      <MemoizedWord
        key={index}
        word={word}
        startIndex={startIndex}
        currentIndex={userInput.length}
        userInput={userInput}
        setCorrectness={setCorrectness}
      />
    );
    startIndex += word.length;
    return wordComponent;
  });

  const caretProps = {
    position: caretPosition,
    isLoading: isLoading,
    isFocused: isFocused,
    shouldBlink: shouldBlinkCaret
  };

  return (
    <TypingDisplay
      displayMode={displayMode}
      currentIndex={userInput.length}
      caretProps={caretProps}
    >
      {content}
    </TypingDisplay>
  );
};


  // const getExpectedHeight = (wordCount) => {
  //   // Assume average of ~8 words per line, ~40px per line
  //   const estimatedLines = Math.ceil(wordCount / 8);
  //   return estimatedLines * 40; 
  // };

  // const getTextHeight = () => {
  //   if (!testContainerRef.current) return null;

  //   // Get all letter elements
  //   const letters = testContainerRef.current.querySelectorAll('[data-index]');
  //   if (!letters.length) return null;

  //   // Get the first and last letter's bounding rectangles
  //   const firstLetter = letters[0];
  //   const lastLetter = letters[letters.length - 1];
  //   const firstRect = firstLetter.getBoundingClientRect();
  //   const lastRect = lastLetter.getBoundingClientRect();

  //   // Calculate total height from first letter to last letter
  //   return lastRect.bottom - firstRect.top;
  // };

  //Claude instructions:
  //You might need to change this return statement to accommodate SlidingDisplay
  //If the wrapping Box/Paper components interfere, please find a solution.
  return (
  <>
    <Box sx={{ position: 'relative' }}>
      {isCapslockOn && <CapsLockWarning />}
      <Paper
        ref={testContainerRef}
        elevation={0}
        onMouseUp={handleMousePress}
        data-tour="typing-test"
        data-typing-container
        sx={{
          position: 'relative',
          borderRadius: '20px',
          borderColor: 'rgba(0, 0, 255, 0)',
          width: '100%',
          mb: 2,
          px: 2,
          py: 2,
          backgroundColor: theme.palette.background.paper,
          display: 'flex',
          lineHeight: 1.5
        }}
      >
        <Box
          sx={{
            width: '100%',
            filter: !isFocused ? 'blur(2px)' : 'none',
            transition: 'filter 0.2s ease-in-out',
          }}
        >
          {isLoading ? (
            <SkeletonStack
              count={currWordCount.current}
              containerRef={testContainerRef}
              height={contentHeight || 'auto'}
            />
          ) : (
            renderStyledText(typingTestText, userInput)
          )}
        </Box>
          {!isFocused && (
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: theme.palette.background.default,
                padding: '0.5em 1em',
                borderRadius: '8px',
              }}
            >
              <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
                Click here or press Ctrl + Shift + F to focus
              </Typography>
            </Box>
          )}
          <input
            type="text"
            value={userInput}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            ref={inputRef}
            readOnly
            style={{
              opacity: 0,
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              zIndex: 6,
              cursor: 'inherit',
            }}
          />
          <TTDialog isOpen={typingTipDialog} onClose={handleClosetypingTipDialog}>
            <TypingTip />
          </TTDialog>
        </Paper>
      </Box>
    </>
  );

};

export { TypingTest }


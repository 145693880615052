import React, { useContext, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Divider, Box, Typography, Tooltip, Slider, Stack, Card } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

import { PatternsCard } from './PatternsCard'
import { InnerDashboard } from './InnerDashboard'
import { ProfilesMenu } from './ProfilesMenu'
import { SettingsCRUD } from './SettingsCRUD.jsx'
import { TTAutoComplete } from './TTAutoComplete'
import { SliderWrapper, SliderWrapper2 } from './SliderWrapper'
import { MainContentContext  } from '../contexts/MainContentContext'
import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx'
import { RestoreDefaultSettingsButton } from './RestoreDefaultSettingsButton'
import { PunctuationSwitch } from './PunctuationSwitch.jsx'
import {
  missedPatternsTooltip, slowPatternsTooltip, userIncludedPatternsTooltip,
  userExcludedPatternsTooltip, saveLoadTooltip
} from '../utils/toolTipTexts.js'
import { CustomPatternsDashboard } from './CustomPatternsDashboard'
import { KeyboardShortcuts } from './KeyboardShortcuts.jsx'
import {
  slowPatternsTemplatesTooltip, inaccuratePatternsTemplatesTooltip,
  freezeTooltip, historyToolTip, randomnessTooltip,
  refreshIntervalTooltip, randomDurationToolTip, wordsHistoryToolTip,
  letterLimitToolTip, usePunctuationToolTip, wordRepetitionToolTip
} from '../utils/toolTipTexts.js'


const PatternsTemplateSelector = () => {
  const theme = useTheme();

  const weaknessOptions = ["Letters", "Bigrams", "Trigrams", "Words", "Spacegrams"]

  const {
    slowPatternTemplates, setSlowPatternTemplates,
    inaccuratePatternTemplates, setInaccuratePatternTemplates,
    desiredWeaknessHistory, setDesiredWeaknessHistory, desiredRefreshInterval,
    setDesiredRefreshInterval, freeze, setFreeze, 
     desiredWordsHistory, setDesiredWordsHistory,
  } = useContext(MainContentContext);

  const {
    desiredRandomness, setDesiredRandomness, letterLimit, setLetterLimit,
    punctuation, setPunctuation, randomDuration, setRandomDuration,
    wordRepetition, setWordRepetition
  } = useContext(SuperMainContentContext);


  const handleFreezeChange = (event) => {
    setFreeze(event.target.checked);
  };

  const handleLetterLimitSlider = (event, newValue) => {
    setLetterLimit(newValue);
  };

  const handlePunctuationChange = (event) => {
    setPunctuation(event.target.checked);
  }

  return (

      <Box
        sx={{
          ...theme.customStyles.patternsBox,
          backgroundColor: theme.palette.background.paper,
          borderColor: theme.palette.divider,
        }}
      >
        <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ width: "100%", m: "0" }}>
          <Grid xs={10}>
            <Typography>
              Slow Patterns Detection
            </Typography>
          </Grid>
          <Grid xs={2}>
            <Box sx={{ padding: 0, textAlign: "right", width: "100%" }}>
              <Tooltip title={slowPatternsTemplatesTooltip} placement="left-start">
                <InfoIcon />
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        <TTAutoComplete
          optionsArr={weaknessOptions}
          label={"Select Desired Patterns"}
          placeholder={"Slow pattern templates"}
          useFreeSolo={false}
          setValues={setSlowPatternTemplates}
          value={slowPatternTemplates}
          usePopupIcon={true} />
        <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ width: "100%", m: "0" }}>
          <Grid xs={10}>
            <Typography>
              Mistyped Patterns Detection
            </Typography>
          </Grid>
          <Grid xs={2}>
            <Box sx={{ padding: 0, textAlign: "right", width: "100%" }}>
              <Tooltip title={inaccuratePatternsTemplatesTooltip} placement="left-start">
                <InfoIcon />
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        <TTAutoComplete
          optionsArr={weaknessOptions}
          label={"Select Desired Patterns"}
          placeholder={"Inaccurate pattern templates"}
          useFreeSolo={false}
          setValues={setInaccuratePatternTemplates}
          value={inaccuratePatternTemplates}
          usePopupIcon={true}
        />
        <PunctuationSwitch
          value={punctuation}
          handlePunctuationChange={handlePunctuationChange}
        />

        {/* This doesn't make much sense since the whole "lesson" concept */}
        {/* <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ width: "100%", m: "0" }}>
        <Grid xs={10}>
          <FormControlLabel sx={{ mt: "1em" }}
            control={<Switch color="secondary" checked={freeze} onChange={handleFreezeChange} />}
            label="Freeze Current Weaknesses"
          />
        </Grid>
        <Grid xs={2}>
          <Box sx={{ padding: 0, textAlign: "right", width: "100%" }}>
            <Tooltip title={freezeTooltip} placement="left-start">
              <ContactSupportIcon />
            </Tooltip>
          </Box>
        </Grid>
      </Grid> */}
      </Box> 
  )
}


const SettingsDashboard = () => {

  const { inaccuratePatterns, setInaccuratePatterns, slowPatterns,
    setSlowPatterns, setSlowPatternsNum, setInaccuratePatternsNum,
    slowPatternsNum, inaccuratePatternsNum,  } = useContext(MainContentContext);

  const [ innerDashboardAccordionExpanded, setInnerDashboardAccordionExpanded ] = useState(true)
  const [ customPatternsaccordionExpanded, setCustomPatternsaccordionExpanded ] = useState(true)


  const handleInaccuratePatternsSlider = (event, newValue) => {
      setInaccuratePatternsNum(newValue);
  };

  const handleSlowPatternsSlider = (event, newValue) => {
      setSlowPatternsNum(newValue);
  };

  return (
    <Grid container spacing={4}
      data-tour="inner-dashboard"
    >
      <Grid xs={12} sm={12} lg={6} >
        {/* <TTAccordion text="User Custom Patterns"
          Icon={DashboardCustomizeIcon}
          expanded={customPatternsaccordionExpanded}
          setExpanded={setCustomPatternsaccordionExpanded}
          width="100%"
        > */}
  
        {/* <CustomPatternsDashboard /> */}
        <PatternsTemplateSelector />
        <SettingsCRUD />
        {/* </TTAccordion> */}
        {/* <br /> */}
        {/* <RestoreDefaultSettingsButton /> */}
        {/* <br /> */}
        {/* <KeyboardShortcuts/> */}
      </Grid>
      <Grid xs={12} sm={12} lg={6}>
        {/* <TTAccordion text="Advanced Settings"
          Icon={TuneIcon}
          expanded={innerDashboardAccordionExpanded}
          setExpanded={setInnerDashboardAccordionExpanded}
          width="100%"
        > */}
        <InnerDashboard />
        {/* </TTAccordion> */}
      </Grid>
    </Grid>
  );
}

export { SettingsDashboard }

const builtInPresets = {
  "Default Settings": {
    settings: {
      // Test configuration
      language: "English",
      testLength: "Medium",
      wordList: "10k",
      
      // Weakness detection
      inaccuratePatternTemplates: ["Bigrams"],
      slowPatternTemplates: ["Trigrams", "Bigrams"],
      
      // Number settings
      weaknessHistory: 6,
      wordsHistory: 6,
      refreshInterval: 10,
      inaccuratePatternsCount: 5,
      slowPatternsCount: 5,
      randomness: 5,
      randomDuration: 1,
      wordRepetition: 1,
      
      // Flags
      punctuation: false
    }
  },

  "MT 200 60s": {
    settings: {
      // Test configuration
      language: "English",
      testLength: "Medium",
      wordList: "200",
      
      // Weakness detection 
      inaccuratePatternTemplates: ["Words"],
      slowPatternTemplates: ["Words"],
      
      // Number settings as shown in image
      weaknessHistory: 6,
      wordsHistory: 0,
      refreshInterval: 12,
      inaccuratePatternsCount: 1,
      slowPatternsCount: 5,
      randomness: 10,
      randomDuration: 1,
      wordRepetition: 3,
      
      // Flags
      punctuation: false
    }
  },

  "MT 200 10w": {
    settings: {
      // Test configuration
      language: "English",
      testLength: "Burst",
      wordList: "200",
      
      // Weakness detection 
      inaccuratePatternTemplates: ["Words"],
      slowPatternTemplates: ["Words"],
      
      // Number settings as shown in image
      weaknessHistory: 6,
      wordsHistory: 1,
      refreshInterval: 12,
      inaccuratePatternsCount: 1,
      slowPatternsCount: 5,
      randomness: 10,
      randomDuration: 1,
      wordRepetition: 1,
      
      // Flags
      punctuation: false
    }
  },

  "Type racing": {
    settings: {
      // Test configuration
      language: "English",
      testLength: "Medium",
      wordList: "5k",

      // Weakness detection
      inaccuratePatternTemplates: ["Bigrams"],
      slowPatternTemplates: ["Trigrams"],

      // Number settings
      weaknessHistory: 6,
      wordsHistory: 6,
      refreshInterval: 10,
      inaccuratePatternsCount: 10,
      slowPatternsCount: 10,
      randomness: 10,
      randomDuration: 1,
      wordRepetition: 1,

      // Flags
      punctuation: true
    }
  },
};

export { builtInPresets };
import React from 'react';
import styles from './TypingTest.module.css';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

function Letter({ 
  letter, 
  isSpace,
  isCurrent,
  correctness,
  index
}) {
  const theme = useTheme();
  let className = styles.letter;
  let color;
  // let fontWeight = fontWeights.default;

  switch (correctness) {
    case 'correct':
      color = theme.palette.mode === 'light' ? theme.palette.success.light : theme.palette.success.dark;
      break;
    case 'corrected':
      // color = theme.palette.mode === 'light' ? theme.palette.warning.light : theme.palette.warning.dark;
      color = theme.palette.mode === 'light' ? theme.palette.success.light : theme.palette.success.dark;
      break;
    case 'incorrect':
      color = theme.palette.mode === 'light' ? theme.palette.error.light : theme.palette.error.dark;
      break;
    default:
      color = theme.palette.text.default;
  }


  return (
    <Box
      component="span"
      className={className}
      sx={{
        color,
        position: 'relative',
        zIndex: 2,  // Ensure the letter is above the caret
        textDecoration: correctness === 'incorrect' ? 'underline' : 'none',
        fontSize: '30px',
        lineHeight: 1.5,
        display: 'inline-block'
      }}
      data-index={index}  // Add this attribute 
    >
      {isSpace ? '\u00A0' : letter}
    </Box>
  );
}

const MemoizedLetter = React.memo(Letter);


  // if (isCurrent) {
  //   className += ` ${styles.current}`;
  //   fontWeight = fontWeights.current;
  // } else if (correctness) {
  //   className += ` ${styles[correctness]}`;
  //   fontWeight = fontWeights.typed;
  // }


export { Letter, MemoizedLetter };
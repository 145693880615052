import { Box,  } from '@mui/material'


function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        backgroundColor: 'transparent',
        textAlign: 'center',
        mt: '10em',
      }}
    >
     © Infinity Typecelerate.
    </Box>
  );
}

export { Footer };
import React from 'react';
import { Typography } from '@mui/material';

const CapsLockWarning = () => {

  return (

    <Typography
      align="center"
      sx={{
        position: 'absolute',
        left: '50%', // Position at center of parent
        transform: 'translateX(-50%) translateY(-150%)', // Move back by half its width
        // transform: '', // Move back by half its width
        mt: 2,
        mb: 2,
        zIndex: 10,
        width: 'auto',
        padding: '0 16px',
      }}
    >
      Caps Lock is on
    </Typography>
  );
};

export { CapsLockWarning }

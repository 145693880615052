import React, {  memo, useState, useEffect, useContext } from 'react';
import { IconButton,  Toolbar, Typography, Button,  Box } from '@mui/material';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { useTheme } from '@mui/material/styles';

//I want a button with icon and text "video library" that would link in a new tab to a youtube playlist
const VideoLibrary = () => {
  const theme = useTheme();

  const handleClick = () => {
    window.open("https://www.youtube.com/@Typecelerate", "_blank")
  }


  return (
    <Box>
      <Button
        startIcon={<LiveTvIcon />} size="large" onClick={handleClick} color="error"
        sx={ {
          //access error color from theme.palette.error.main
            color: theme.palette.text.primary,
            // backgroundColor: 'black',
            '&:hover': {
              backgroundColor: theme.palette.error.light
            } }
        }
      >
        Video Library
      </Button>
    </Box>
  );
}

export { VideoLibrary };

import './App.css';
import React, { useContext, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Typography } from '@mui/material'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import LinearProgress from '@mui/material/LinearProgress';
import Backdrop from '@mui/material/Backdrop';

import { TourProvider } from '@reactour/tour'
import { HelmetProvider, Helmet } from 'react-helmet-async';

import { TTDialog } from './components/TTDialog'
import { Layout } from './components/Layout'
import { MainContent } from './components/MainContent'
import NonDisclosureAgreement from './components/NonDisclosureAgreement'
import { PrivacyPolicyTermsOfServicePage } from './components/PrivacyPolicyTermsOfServicePage';
import { AppInfo } from './components/AppInfo.jsx';
import Contributors from './components/Contributors';
import { MetaTags } from './components/MetaTags.jsx';

import { AppContext } from './contexts/AppContext.jsx'
import { SuperMainProvider } from './contexts/SuperMainContext.jsx'

import palettes from './themes/palettes.js';

import { createTourSteps } from './utils/tourSteps.js';
import createCommonSettings from './utils/createCommonSettings';


function App() {
  const { currUser, isUserLoading, isWebsiteReady, showNDA } = useContext(AppContext);

  const { displayedPalette, setIsFocused, displayedFont, setIsSidebarOpen } = useContext(AppContext);
  const commonSettings = createCommonSettings(displayedPalette, displayedFont)

  const steps = useMemo(() =>
    createTourSteps(setIsFocused, setIsSidebarOpen),
    [setIsFocused, setIsSidebarOpen]
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.shiftKey && event.key.length === 1 && event.key.toLowerCase() === 'f') {
        setIsFocused(true);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // console.log('App.js rendered');

  let theme = useMemo(() => {
    return createTheme({
      ...palettes[displayedPalette],
      ...commonSettings,
      transitions: {
        duration: {
          colorMode: 1000, // Duration of the color transition in milliseconds
        },
      },
    });
  }, [displayedPalette, commonSettings]);

  theme = responsiveFontSizes(theme);

// theme = responsiveFontSizes(theme, {
//   breakpoints: ['sm', 'md', 'lg', 'xl', '4k'],
//   factor: 2,
//   variants: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2'],
// });


  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <SuperMainProvider>
          <CssBaseline />
          <MetaTags/>
          <TourProvider
            steps={steps}
            onClickMask={() => { }}
            styles={{
              popover: (base) => ({
                ...base,
                '--reactour-accent': theme.palette.primary.main,
                background: theme.palette.background.paper,
                color: theme.palette.text.primary,
                margin: 20,
              }),
              dot: (base, { current }) => ({
                ...base,
                borderColor: current ? theme.palette.primary.main : theme.palette.text.primary,
                backgroundColor: current ? theme.palette.primary.main : 'transparent',
              }),
              close: (base) => ({
                ...base,
                right: -8,
                top: -12,
                width: 34,
                height: 30,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.text.primary,
                borderRadius: '50%',
                boxShadow: '-2px 3px 8px 2px rgba(0, 0, 0, 0.2)', // Adds subtle shadow
                padding: 12,
                color: 'white',
                display: 'flex',
                alignItems: 'center',     // Center vertically
                justifyContent: 'center', // Center horizontally
              }),
            }}
          >
            <Router>
              {!isWebsiteReady ? (
                <Backdrop
                  open={!isWebsiteReady}
                  sx={{
                    zIndex: 130,
                    backgroundColor: '#2d2d2d', // Solid dark grey
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#f5f5f5', // Light grey text
                      fontWeight: 500,
                      marginBottom: 2,
                      letterSpacing: '0.05em'
                    }}
                  >
                    Loading
                  </Typography>
                  <LinearProgress
                    sx={{
                      width: '50%',
                      marginBottom: 2,
                      backgroundColor: '#404040', // Darker grey background
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#e0e0e0', // Lighter grey for the progress bar
                      },
                      borderRadius: 1,
                    }}
                  />
                </Backdrop>
              ) : null}

                <TTDialog isOpen={showNDA}>
                  <NonDisclosureAgreement />
                </TTDialog>

              <Routes>
                <Route path="/contributors" element={
                  <Layout>
                    <Contributors />
                  </Layout>
                } />
                <Route path="/privacy-policy-and-terms-of-service" element={
                  <Layout>
                    <PrivacyPolicyTermsOfServicePage />
                  </Layout>
                } />
                <Route path="/info" element={
                  <Layout>
                    <AppInfo />
                  </Layout>
                } />
                <Route path="/" element={
                  <Layout>
                    <MainContent />
                  </Layout>
                } />
              </Routes>
            </Router>
          </TourProvider>
        </SuperMainProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
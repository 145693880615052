import React, { 
  useState, 
  useEffect, 
  useContext, 
  useRef, 
  memo 
} from 'react';
import { Box } from '@mui/material';
import { SuperMainContentContext } from '../../../contexts/SuperMainContext';
import { isRTL } from '../../TypingTest';

const ScrollingDisplay = ({ 
  children, 
  currentIndex 
}) => {
  const { selectedLanguage, isLoading } = useContext(SuperMainContentContext);
  const [visibleLines, setVisibleLines] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!children || children.length === 0) return;

    // Determine the current line
    const currentLine = Math.floor(currentIndex / 8); // Assuming ~8 words per line

    // Show max 3 lines - current line and surrounding lines
    const startLine = Math.max(0, currentLine - 1);
    const endLine = startLine + 3;

    const newVisibleLines = children.slice(
      startLine * 8, 
      endLine * 8
    );

    setVisibleLines(newVisibleLines);
  }, [children, currentIndex]);

  return (
    <Box 
      ref={containerRef}
      sx={{
        display: 'flex',
        flexWrap: 'wrap', 
        width: '100%',
        height: '120px', // Fixed height to show ~3 lines
        overflow: 'hidden',
        direction: isRTL(selectedLanguage) ? 'rtl' : 'ltr',
        visibility: isLoading ? 'hidden' : 'visible',
        transition: 'transform 0.3s ease-out',
        transform: `translateY(-${Math.floor(currentIndex / 8) * 40}px)` // Smooth vertical scroll
      }}
    >
      {visibleLines}
    </Box>
  );
};

export default memo(ScrollingDisplay);
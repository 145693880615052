import React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import {
  slowPatternsTemplatesTooltip, inaccuratePatternsTemplatesTooltip,
  freezeTooltip, historyToolTip, randomnessTooltip,
  refreshIntervalTooltip, randomDurationToolTip, wordsHistoryToolTip,
  letterLimitToolTip, usePunctuationToolTip, wordRepetitionToolTip
} from '../utils/toolTipTexts.js'



function PunctuationSwitch({ value, handlePunctuationChange }) {

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ width: "100%", m: "0" }}>
      <Grid xs={10}>
        <FormControlLabel sx={{ mt: "1em" }}
          control={<Switch color="secondary" checked={value} onChange={handlePunctuationChange} />}
          label="Use Punctuation in Tests"
        />
      </Grid>
      <Grid xs={2}>
        <Box sx={{ padding: 0, textAlign: "right", width: "100%" }}>
          <Tooltip title={usePunctuationToolTip} placement="left-start">
            <InfoIcon />
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
}

export { PunctuationSwitch }
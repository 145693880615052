import { memo } from 'react';
import { useContext } from 'react';
import { Box } from '@mui/material';
import { SuperMainContentContext } from '../../../contexts/SuperMainContext';
import { isRTL } from '../../TypingTest';

const StaticDisplay = ({ children }) => {
 const { selectedLanguage, isLoading } = useContext(SuperMainContentContext);
 
 return (
   <Box sx={{
     display: 'flex',
     flexWrap: 'wrap', 
     width: '100%',
     direction: isRTL(selectedLanguage) ? 'rtl' : 'ltr',
     visibility: isLoading ? 'hidden' : 'visible'
   }}>
     {children}
   </Box>
 );
};


export default memo(StaticDisplay);

import React, { useContext, } from 'react';
import { Box, Dialog } from '@mui/material'
import { AppContext } from '../contexts/AppContext.jsx'

function TTDialog({ children, isOpen, onClose }) {
  const {setIsFocused} = useContext(AppContext);

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown' || reason === 'enterKeyDown') {
      // Handle closing the dialog on backdrop click here
      onClose?.(); // Optional: Call the onClose prop passed from outside if available
      setIsFocused(true);
    }
  };

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    onClose?.();
    setIsFocused(true);
  }
};

  return (
    <Dialog open={isOpen} onClose={handleClose} onKeyDown={handleKeyDown} >
      <Box>
        {React.Children.map(children, child =>
          React.cloneElement(child, { onClose })
        )}
      </Box>
    </Dialog>
  );

};

export { TTDialog };
import React, { useMemo } from 'react';
import { Stack, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { wordCounts } from '../utils/defaultValues';

const DynamicSkeletonStack = ({count, containerRef, height }) => {
  const displayLineHeight = 30;

  // const skeletonCount = useMemo(() => {
  //   if (!height || height <= 0) return 1;
  //   return Math.ceil(height / displayLineHeight);  // Remove spacing from calculation
  // }, [height]);

  const skeletonCount = useMemo(() => {
    switch (count) {
      case wordCounts.Burst:
        return 1;
      case wordCounts.Short:
        return 2;
      case wordCounts.Medium:
        return 5;
      case wordCounts.Long:
        return 8;
      default:
        return 1;
    }
  }, [count]);

  return (
    <Stack
      spacing={1.5}  // Use MUI's built-in spacing
      width="100%"
      height={height || 'auto'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-start'
      }}
    >
      {Array.from({ length: skeletonCount }).map((_, index) => (
        <Skeleton
          key={index}
          height={displayLineHeight}
          width="100%"
          animation="wave"
          variant="text"
          sx={{
            fontSize: '20px',
            transform: 'none'
          }}
        />
      ))}
    </Stack>
  );
};

export default DynamicSkeletonStack;
import React, {  memo, useState, useEffect, useContext } from 'react';
import {
  AppBar, Button, ButtonGroup, Tooltip, Typography,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Stack, CircularProgress,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// import { AuthButton } from './AuthButtonAuth0';
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { AuthButton } from './AuthButtonFirebase';
import { FaRoad } from "react-icons/fa";
import { IoMdDocument } from "react-icons/io";
import { RiPresentationFill } from "react-icons/ri";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { useTour } from '@reactour/tour'

import { PaletteSelector } from './PaletteSelector';
import FontSelector from './FontSelector';
import AvatarSelector from './AvatarSelector';
import RoadMap from './RoadMap';
import PayPalSubscriptionButton from './PayPalSubscriptionButton';

import { AppContext } from '../contexts/AppContext.jsx';

const ContactDialog = ({ open, onClose }) => {
  return (

        <Dialog open={open} onClose={onClose}>
          <DialogTitle>Contact</DialogTitle>
          <DialogContent dividers>

            <Stack spacing={2}>
              <Button variant="contained" aria-label="Open X"
                onClick={() => window.open("https://x.com/Typecelerate", "_blank")}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Contact me on X
                </Typography>
              </Button>

              <Button variant="contained" aria-label="Open subreddit"
                onClick={() => window.open("https://reddit.com/r/Typecelerate", "_blank")}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Join the subreddit
                </Typography>
              </Button>

            </Stack>

          </DialogContent>

          <DialogActions>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
  );
}



const Header = memo(() => {
  const [roadMapOpen, setRoadmapOpen] = useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const { currUser, isUserLoading, setIsFocused } = useContext(AppContext);
  const { setIsOpen } = useTour()

  useEffect(() => {
    const firstVisit = localStorage.getItem('firstVisit');
    if (!firstVisit) {
      setIsOpen(true);
      localStorage.setItem('firstVisit', 'true');
    }
  }, [setIsOpen]);

  const theme = useTheme();
  const navigate = useNavigate();
  
  const navigateToRoot = () => {
    navigate('/');
    setIsFocused(true);
  }

  const handleClickOpenToSRoute = () => {
    navigate('/privacy-policy-and-terms-of-service');
  }

  const navigateToAppInfo = () => {
    navigate('/info');
  }

  const handleOpenRoadmap = () => {
    setRoadmapOpen(true);
  }

  const handleCloseRoadmap = () => {
    setRoadmapOpen(false);
  }

  const handleCloseContact = () => {
    setContactOpen(false);
  }

  const handleClosePaymentDialog = () => {
    setPaymentDialogOpen(false);
  }

  const [contactOpen, setContactOpen] = useState(false);

  return (
    <AppBar position="static" enableColorOnDark={true} color='primary'
      sx={{
        backgroundColor: theme.palette.primary.main,
        mb: '1em',
      }}  >
      <ButtonGroup
        disableElevation
        variant="contained"
        size="large"
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& .MuiButtonGroup-grouped': {
            minWidth: 0,
            px: '0.8em',
          },
          '& > button': {
            marginRight: '0px',
          }
        }}
      >
        <AuthButton />

        <Tooltip title="Typing Practice" data-tour="typing-practice">
          <Button startIcon={<KeyboardIcon />} aria-label="Typing Practice" onClick={navigateToRoot} >Typing Practice</Button>
        </Tooltip>

        <Tooltip title="Onboarding" data-tour="onboarding" >
          <Button startIcon={<RiPresentationFill />} aria-label="Onboarding" onClick={() => setIsOpen(true)} >Onboarding Tour</Button>
        </Tooltip>

        <Tooltip title="Info">
          <Button startIcon={<InfoIcon />} aria-label="info" onClick={navigateToAppInfo} >Information</Button>
        </Tooltip>

        <Tooltip title="Contact" data-tour="contact">
          <Button startIcon={<EmailIcon />} aria-label="contact" onClick={() => setContactOpen(true)} >Contact</Button>
        </Tooltip>

        <Tooltip title="Roadmap">
          <Button startIcon={<FaRoad />} aria-label="Roadmap" onClick={handleOpenRoadmap} >Roadmap</Button>
        </Tooltip>

        <Tooltip title="Privacy Policy & Terms of Service">
          <Button startIcon={<IoMdDocument />} aria-label="Privacy policy & Terms of Service"
            // onClick={handleClickOpenToSDialog}
            onClick={handleClickOpenToSRoute}
          >Terms of Service</Button>
        </Tooltip>

        <Tooltip title="Subscribe">
          <Button aria-label="Subscribe" startIcon={<AddShoppingCartIcon />} onClick={() => setPaymentDialogOpen(true)} >Subscribe</Button>
        </Tooltip>

        <PaletteSelector />
        <AvatarSelector />
        <FontSelector />

        <ContactDialog open={contactOpen} onClose={handleCloseContact} />


        <Dialog open={roadMapOpen} onClose={handleCloseRoadmap}>
          <DialogTitle>Roadmap</DialogTitle>
          <DialogContent dividers>
            <RoadMap />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseRoadmap} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={paymentDialogOpen} onClose={handleClosePaymentDialog}>
          <DialogTitle>Subscribe to Typecelerate</DialogTitle>
          <DialogContent dividers>
            {isUserLoading ? (
              <CircularProgress />
            ) : currUser ? (
              <PayPalSubscriptionButton userId={currUser.uid} />
            ) : (
              <Typography variant="body2" paragraph>
                You need to be logged in to subscribe to Typecelerate.
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePaymentDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

      </ButtonGroup>
    </AppBar>
  );
})

export { Header, ContactDialog };
const defaultSlowPatternTemplates = ["Trigrams", "Bigrams"];
const defaultInaccuratePatternTemplates = ["Bigrams"];
const defaultDesiredWeaknessHistory = 6;
const defaultDesiredWordsHistory = 6;
const defaultDesiredRefreshInterval = 10;
const defaultInaccuratePatternsCount = 5;
const defaultSlowPatternsCount = 5;
const defaultDesiredRandomness = 5;
const defaultRandomDuration = 1;
const defaultLanguage = 'English';
const defaultWordList = '10k';
const defaultTestLength = 'Medium';
const defaultLetterLimit = 25;
const defaultWordRepetition = 1;

const testLengths = ['Burst', 'Short', 'Medium', 'Long'];
const wordListSizes = ['200', /*'500',*/ '1k', '5k', '10k'];
const languages = [
  'Arabic',
  'English',
  'English UK',
  'Hebrew',
  'Italian',
  'Portuguese',
  'Russian',
  'Spanish',
  'Numbers',
  'Special Characters',
  // 'Emojis',
];

const wordCounts = {
  Burst: 10,
  Short: 18,
  Medium: 39,
  Long: 69,
};

const languageCodes = {
  English: 'en',
  'English UK': 'en-GB',
  Spanish: 'es',
  French: 'fr',
  Italian: 'it',
  German: 'de',
  Arabic: 'ar',
  Portuguese: 'pt',
  Russian: 'ru',
  Hebrew: 'he',
  Chinese: 'zh',
  Numbers: 'nu',
  'Special Characters': 'sc',
  // Emojis: 'em',
};

export {
  defaultSlowPatternTemplates, defaultInaccuratePatternTemplates,
  defaultDesiredWeaknessHistory, defaultDesiredRefreshInterval,
  defaultInaccuratePatternsCount, defaultSlowPatternsCount,
  defaultDesiredRandomness, defaultRandomDuration, wordCounts,
  languageCodes, wordListSizes, testLengths,
  defaultLanguage, defaultWordList, defaultTestLength,
  languages, defaultDesiredWordsHistory, defaultLetterLimit,
  defaultWordRepetition
};
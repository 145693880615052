import { Helmet } from 'react-helmet-async';

const MetaTags = () => {
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>Typecelerate - Improve Your Typing Skills</title>
      <meta name="description" content="Enhance your typing speed and accuracy with Typecelerate, a customizable typing practice tool that adapts to your needs." />
      <meta name="keywords" content="typing test, speed typing, touch typing, typing practice, typing skills, typing tutor" />
      
      {/* Open Graph Meta Tags for Social Media */}
      <meta property="og:title" content="Typecelerate - Improve Your Typing Skills" />
      <meta property="og:description" content="Enhance your typing speed and accuracy with Typecelerate, a customizable typing practice tool that adapts to your needs." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://typecelerate.com" />
      <meta property="og:image" content="https://typecelerate.com/og-image.png" />
      <meta property="og:image:secure_url" content="https://typecelerate.com/og-image.png" />
      <meta property="og:image:type" content="image/png" /> {/* Add explicit MIME type */}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content="Typecelerate" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Typecelerate" />
      <meta name="twitter:title" content="Typecelerate - Improve Your Typing Skills" />
      <meta name="twitter:description" content="Enhance your typing speed and accuracy with Typecelerate, a customizable typing practice tool that adapts to your needs." />
      <meta name="twitter:image" content="https://typecelerate.com/og-image.png" />

      
      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="author" content="Typecelerate" />
      <meta name="theme-color" content="#2d2d2d" />
      
      {/* Canonical URL */}
      <link rel="canonical" href="https://typecelerate.com" />
      
      {/* Favicon */}
      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" href="/logo192.png" />
    </Helmet>
  );
};

export { MetaTags };
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { logger } from "./logger";

const developmentConfig = {
  apiKey: "AIzaSyAJ8kKpjGfF5enlbp4LIGC74h5D-lOCpdw",
  authDomain: "typecelerate-35446.firebaseapp.com",
  projectId: "typecelerate-35446",
  storageBucket: "typecelerate-35446.firebasestorage.app",
  messagingSenderId: "746867931829",
  appId: "1:746867931829:web:51fe97356be1d2b8059cfc",
  measurementId: "G-W2HRH1QRHF"
};

const productionConfig = {
  apiKey: "AIzaSyAmGXHHR_1eeURTOWuA_AN8PPgWz2mtL4Q",
  authDomain: "typecelerate-production.firebaseapp.com",
  projectId: "typecelerate-production",
  storageBucket: "typecelerate-production.firebasestorage.app",
  messagingSenderId: "570441336650",
  appId: "1:570441336650:web:285a0be84bc918343963b3",
  measurementId: "G-M6ZPGX6L5J"
};

let firebaseConfig = process.env.REACT_APP_ENV === 'production' 
  ? productionConfig 
  : developmentConfig;

// firebaseConfig = productionConfig; //sometimes for debug

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

export { firebaseApp, auth, db};
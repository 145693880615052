import React from "react";
import { List, ListItem, ListItemText } from "@mui/material";

const ListItemWithText = ({ primary }) => {
  return (
    <ListItem>
      <ListItemText primary={primary} />
    </ListItem>
  );
}

const RoadMap = () => {
  return (
    <List>
      {/* <ListItemWithText primary="adsense to support hosting services" /> */}
      {/* <ListItemWithText primary="premium Features" /> */}
      <ListItemWithText primary="Implement user requested features" />
    </List>
  );
};

export default RoadMap;
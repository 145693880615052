import React, { useContext, useState, useMemo } from 'react';
import { Box, TextField, Typography, Paper, Stack } from '@mui/material';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer,
  ReferenceLine 
} from 'recharts';
import { MainContentContext } from '../contexts/MainContentContext';
import { useTheme } from '@mui/material/styles';
// import { AdvancedSimpleToggle, SimplifiedTestResults } from './TypingTestResults';

const SessionSummary = () => {
  const { sessionWPMscores } = useContext(MainContentContext);
  const [goalWPM, setGoalWPM] = useState('');

  const theme = useTheme();

  // Calculate statistics
  const stats = useMemo(() => {
    if (sessionWPMscores.length === 0) return { min: 0, max: 0, avg: 0 };
    return {
      min: Math.min(...sessionWPMscores),
      max: Math.max(...sessionWPMscores),
      avg: Math.round(sessionWPMscores.reduce((a, b) => a + b) / sessionWPMscores.length)
    };
  }, [sessionWPMscores]);

  // Transform the scores array into the format recharts expects
  const data = sessionWPMscores.map((score, index) => ({
    testNumber: `Test ${index + 1}`,
    wpm: score
  }));

  const handleGoalChange = (event) => {
    const value = event.target.value;
    if (value === '' || (!isNaN(value) && value >= 0)) {
      setGoalWPM(value);
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box sx={{ 
          bgcolor: theme.palette.background.paper, 
          p: 1, 
          border: 1, 
          borderColor: 'divider',
          borderRadius: 1
        }}>
          <Typography variant="body2">{label}</Typography>
          <Typography variant="body2">
            WPM: {payload[0].value}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const yDomain = useMemo(() => {
    if (sessionWPMscores.length === 0) return [0, 100];
  
    const minScore = Math.min(...sessionWPMscores);
    const maxScore = Math.max(...sessionWPMscores);
    const goalWpmNum = Number(goalWPM);
  
    // Consider goal WPM in the range if it exists
    const effectiveMax = goalWpmNum ? Math.max(maxScore, goalWpmNum) : maxScore;
    const effectiveMin = Math.min(minScore, goalWpmNum || minScore);

    // Round down min to nearest 10, round up max to nearest 10
    const yMin = Math.floor(effectiveMin / 10) * 10;
    const yMax = Math.ceil(effectiveMax / 10) * 10;

    return [yMin, yMax];
  }, [sessionWPMscores, goalWPM]);

  return (
    // <Paper sx={{ width: '100%', p: 2 }}>
      <Box
        sx={{
          ...theme.customStyles.patternsBox,
          backgroundColor: theme.palette.background.default,
          // borderColor: theme.palette.divider,
          borderColor: 'transparent',
        }}
      >
        {/* <Stack direction="row" spacing={2} alignItems="center">
        <AdvancedSimpleToggle/>
        <Typography variant="h6">
          {goalWPM && `Goal: ${goalWPM} WPM | `}
          Average WPM: {stats.avg}</Typography>
        | Max: {stats.max} WPM | Min: {stats.min} WPM
          <SimplifiedTestResults/>
        </Stack>
       */}
      <Box
        sx={{
          width: '100%',
          height: '8em',
          my: 2,
        }}
      >
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
            />
            <XAxis
              dataKey="testNumber"
              // line color
              stroke={theme.palette.text.primary}
            />
            <YAxis
              domain={yDomain}
              stroke={theme.palette.text.primary}
            />
            <Tooltip content={<CustomTooltip />} />
            
            {/* Min Line */}
            {/* <ReferenceLine
              y={stats.min}
              stroke={theme.palette.error.main}
              strokeDasharray="3 3"
              label={{
                value: `Min: ${stats.min}`,
                position: 'left',
                fill: theme.palette.error.main,
              }}
            /> */}
            
            {/* Max Line */}
            {/* <ReferenceLine
              y={stats.max}
              stroke={theme.palette.success.main}
              strokeDasharray="3 3"
              label={{
                value: `Max: ${stats.max}`,
                position: 'left',
                fill: theme.palette.success.main,
              }}
            /> */}
            
            {/* Average Line */}
            <ReferenceLine
              y={stats.avg}
              stroke={theme.palette.text.primary}
              // strokeDasharray="3 3"
              label={{
                value: `Avg: ${stats.avg}`,
                position: 'middle',
                fill: theme.palette.text.primary,
              }}
            />
            
            {/* Goal Line (if set) */}
            {goalWPM && (
              <ReferenceLine
                y={Number(goalWPM)}
                // stroke={theme.palette.text.primary}
                strokeDasharray="3 3"
                label={{
                  value: `Goal: ${goalWPM}`,
                  position: 'middle',
                  fill: theme.palette.text.primary
                }}
              />
            )}
            
            {/* Main WPM Line */}
            <Line
              type="monotone"
              dataKey="wpm"
              // stroke="#8884d8"
              strokeWidth={2}
              dot={{ r: 4 }}
              animationDuration={500}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
</Box>
  );

};

export default SessionSummary;
import React, { useState, useContext, useEffect } from 'react';
import {
  Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
  IconButton, AppBar, Toolbar, Typography, Button, Dialog, DialogTitle,
  DialogContent, DialogActions, Stack, CircularProgress, Box
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// Import your existing icons and components
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import { FaRoad } from "react-icons/fa";
import { IoMdDocument } from "react-icons/io";
import GroupIcon from '@mui/icons-material/Group';
import { RiPresentationFill } from "react-icons/ri";
// import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { useTour } from '@reactour/tour'

import { PaletteSelector } from './PaletteSelector';
import { VideoLibrary } from './VideoLibrary';
import FontSelector from './FontSelector';
import AvatarSelector from './AvatarSelector';
import RoadMap from './RoadMap';
import PayPalSubscriptionButton from './PayPalSubscriptionButton';
import { ContactDialog } from './Header';
import { AuthButton } from './AuthButtonFirebase';

import { AppContext } from '../contexts/AppContext';
import { SuperMainContentContext } from '../contexts/SuperMainContext';

import { getUserSettings, updateUserTourStatus } from '../utils/firestoreUtils';
import {HomeButtonWithLogo} from './HomeButtonWithLogo.jsx';


const SidebarMenu = () => {
  const [roadMapOpen, setRoadmapOpen] = useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const {
    currUser,
    isUserLoading,
    setIsFocused,
    isSidebarOpen,
    setIsSidebarOpen,
    isWebsiteReady,
    showNDA
  } = useContext(AppContext);
  const {
    setStatisticsAccordionExpanded,
    setPatternsAccordionExpanded
  } = useContext(SuperMainContentContext);
  const { setIsOpen } = useTour();
  const theme = useTheme();
  const navigate = useNavigate();

  //Why is it here?
  useEffect(() => {
    const checkAndShowTour = async () => {
      const browserFirstVisit = !localStorage.getItem('notFirstVisit');
    
      if (currUser && !isUserLoading && isWebsiteReady) {
        try {
          const userSettings = await getUserSettings(currUser.uid);
          if (!userSettings.hasSeenTour) {
            console.log('User has not seen tour yet');
            setIsOpen(true);
            await updateUserTourStatus(currUser.uid, true);
            // localStorage.setItem('notFirstVisit', 'true');
          }
          else {
            console.log('User has seen tour already');
          }
        } catch (error) {
          console.error('Error checking tour status:', error);
        }
      } else if (browserFirstVisit && !showNDA) {
        console.log(`Non-user has not seen the tour yet: browserFirstVisit: ${browserFirstVisit}, showNDA = ${showNDA}`);
        setIsOpen(true);
        localStorage.setItem('notFirstVisit', 'true');
      }
      else {
        console.log('non-user has seen tour already');
        setIsOpen(false);
      }
    };

    checkAndShowTour();
  }, [setIsOpen, currUser, isUserLoading, isWebsiteReady, showNDA]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsSidebarOpen(open);
  };

  const navigateToRoot = () => {
    navigate('/');
    setIsFocused(true);
    setIsSidebarOpen(false);
  };

  const handleClickOpenToSRoute = () => {
    navigate('/privacy-policy-and-terms-of-service');
    setIsSidebarOpen(false);
  };

  const navigateToAppInfo = () => {
    navigate('/info');
    setIsSidebarOpen(false);
  };

  const handleOpenRoadmap = () => {
    setRoadmapOpen(true);
    setIsSidebarOpen(false);
  };

  const handleCloseRoadmap = () => {
    setRoadmapOpen(false);
  };

  const handleOpenContact = () => {
    setContactOpen(true);
    setIsSidebarOpen(false);
  };

  const handleCloseContact = () => {
    setContactOpen(false);
  };

  const handleOpenPaymentDialog = () => {
    setPaymentDialogOpen(true);
    setIsSidebarOpen(false);
  };

  const handleClosePaymentDialog = () => {
    setPaymentDialogOpen(false);
  };

  const navigateToContributors = () => {
    navigate('/contributors');
    setIsSidebarOpen(false);
  }

  const handleStartTour = () => {
    // First close the sidebar
    setIsSidebarOpen(false);
    setStatisticsAccordionExpanded(true);
    setPatternsAccordionExpanded(true);
    // Wait for sidebar animation to complete before starting tour
    setTimeout(() => {
      setIsOpen(true);
    }, 300);
  };

  const menuItems = [
    { text: 'Onboarding Tour', icon: <RiPresentationFill size={20} />, onClick: () => handleStartTour(), dataTour: "onboarding" },
    { text: 'Information', icon: <InfoIcon fontSize="small" />, onClick: navigateToAppInfo },
    //TODO bring back once there's a reason to subscribe
    // { text: 'Subscribe', icon: <AddShoppingCartIcon fontSize="small" />, onClick: handleOpenPaymentDialog },
    { text: 'Contact', icon: <EmailIcon fontSize="small" />, onClick: handleOpenContact, dataTour: "contact" },
    // { text: 'Roadmap', icon: <FaRoad size={20} />, onClick: handleOpenRoadmap },
    { text: 'Terms of Service', icon: <IoMdDocument size={20} />, onClick: handleClickOpenToSRoute },
    // { text: 'Contributors', icon: <GroupIcon fontSize="small" />, onClick: navigateToContributors },
  ];

  const list = () => (
    <List>
      {menuItems.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemButton onClick={item.onClick} data-tour={item.dataTour}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      <AppBar
        position="static"
        enableColorOnDark={true}
        color='primary'
        sx={{
          backgroundColor: theme.palette.primary.main,
          mb: '1em',
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <IconButton
              data-tour="appbar-button"
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon fontSize="large"/>
            </IconButton>
            <HomeButtonWithLogo onClick={navigateToRoot} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <VideoLibrary/>
            <PaletteSelector />
            <AvatarSelector />
            <FontSelector />
            <AuthButton />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        data-tour="sidebar-drawer"
        anchor="left"
        open={isSidebarOpen}
        onClose={toggleDrawer(false)}
        // hideBackdrop={true}
        variant="temporary"
      >
        {list()}
      </Drawer>

      {/* Dialogs */}
      <ContactDialog open={contactOpen} onClose={handleCloseContact} />

      <Dialog open={roadMapOpen} onClose={handleCloseRoadmap}>
        <DialogTitle>Roadmap</DialogTitle>
        <DialogContent dividers>
          <RoadMap />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRoadmap} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={paymentDialogOpen} onClose={handleClosePaymentDialog}>
        <DialogTitle>Subscribe to Typecelerate</DialogTitle>
        <DialogContent dividers>
          {isUserLoading ? (
            <CircularProgress />
          ) : currUser ? (
            <PayPalSubscriptionButton userId={currUser.uid} />
          ) : (
            <Typography variant="body2" paragraph>
              You need to be logged in to subscribe to Typecelerate.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaymentDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

};

export default SidebarMenu;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AppProvider } from './contexts/AppContext'

//Change console.log to not do anything
//When you run npm start, NODE_ENV is set to 'development'
//When you run npm run build, NODE_ENV is set to 'production'
//When you run npm test, NODE_ENV is set to 'test'

if (process.env.NODE_ENV === 'production') {
// if (true) { //sometimes for debug you know
  let logEnabled = false;

  // Store original console.log
  const originalLog = console.log;

  // Suppress logs by default
  console.log = function() {};

  // Expose a global function to enable logs
  window.enableLogs = function() {
    logEnabled = !logEnabled;
    console.log = logEnabled ? originalLog : function() {};
    console.log(logEnabled ? 'Console logging enabled' : 'Console logging disabled');
  };

  // Initial message in console
  // console.info("Type 'enableLogs()' to toggle console logging.");
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
);

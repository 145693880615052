import React, { createContext, useState, useEffect, useRef, useCallback } from 'react';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import { debounce } from 'lodash'; // Make sure to import lodash or implement your own debounce function
import britishVariants from '../assets/mt_lists/britishenglish.json';


import { SingleTestAnalysis, HistoryManager, PatternsPacker } from '../utils/mainAlgorithm';
import { defaultDesiredRandomness, wordCounts, languageCodes,
  wordListSizes, testLengths, defaultLanguage, defaultWordList,
  defaultTestLength, defaultLetterLimit, defaultRandomDuration,
  defaultWordRepetition } from '../utils/defaultValues';
import avatarIcons from '../utils/avatarIcons.js';

import wordLists from '../assets/wordLists.js';

export const DisplayMode = {
 STATIC: 'static', 
 SCROLLING: 'scrolling',
 SLIDING: 'sliding'
};

export function getTestConfiguration(selectedTestLength, selectedWordList, language = 'English') {
  const wordCount = wordCounts[selectedTestLength];
  console.log(
    {
      selectedTestLength,
      selectedWordList,
      language,
      wordCount
    }
  )
  if (wordCount === undefined) {
    throw new Error("Invalid test length");
  }
  const UK_english = 'English UK';

  const baseLanguage = language === UK_english ? 'English' : language;
  const languageCode = languageCodes[baseLanguage];
  if (!languageCode) {
    throw new Error("Invalid language");
  }

  if (!wordListSizes.includes(selectedWordList)) {
    throw new Error("Invalid word list size");
  }

  const wordListKey = `${languageCode}_${selectedWordList}`;
  let wordList = wordLists[wordListKey];

  if (!wordList) {
    throw new Error("Word list not found for the specified language and size" 
      + ` (${languageCode}, ${selectedWordList})`
    );
  }

  if (language === UK_english) {
    // Convert the array format to a Map for efficient lookup
    const britishMap = new Map(britishVariants);
    wordList = wordList.map(word => britishMap.get(word) || word);
  }

  return { wordCount, wordList };
}


export const SuperMainContentContext = createContext();
export const SuperMainProvider = ({ children }) => {
  const isFullyRandom = useRef(false);
  const [testStatus, setTestStatus] = useState('');
  const testAnalysisRef = useRef(new SingleTestAnalysis(isFullyRandom, setTestStatus));
  const [typingTestText, setTypingTestText] = useState('');
  const [algorithmFoundWords, setAlgorithmFoundWords] = useState(true);
  const [isLoading, setIsLoading] = useState(true)
  const isInitialMount = useRef(true);
  const randomPhase = useRef(true);
  const [showNewRecord, setShowNewRecord] = useState(false);
  const [testEnded, setTestEnded] = useState(false);
  const [displayMode, setDisplayMode] = useState(DisplayMode.STATIC);
  const [punctuation, setPunctuation] = useState(() => {
    const savedPunctuation = localStorage.getItem('punctuation');
    return savedPunctuation === 'true';
  });

  useEffect(() => {
    localStorage.setItem('punctuation', punctuation.toString());
  }, [punctuation]);

  const [randomDuration, setRandomDuration] = useState(() => {
    const savedRandomDuration = localStorage.getItem('randomDuration');
    const parsedRandomDuration = parseInt(savedRandomDuration, 10);
    return !isNaN(parsedRandomDuration) && parsedRandomDuration >= 0 ?
      parsedRandomDuration : defaultRandomDuration;
  });

  useEffect(() => {
    localStorage.setItem('randomDuration', randomDuration.toString());
  }, [randomDuration]);
  // const [avatarIcon, setAvatarIcon] = useState(ElderlyWomanIcon); 
  //TODO nimw : keep in localStorage (and later in DB), together with the theme
  const [avatarIcon, setAvatarIcon] = useState(() => {
    const savedIconName = localStorage.getItem('avatarIcon');
    const savedIcon = avatarIcons.find(icon => icon.name === savedIconName);
    return savedIcon ? savedIcon.icon : TwoWheelerIcon;
  });

  useEffect(() => {
    const iconName = avatarIcons.find(icon => icon.icon === avatarIcon)?.name;
    if (iconName) {
      localStorage.setItem('avatarIcon', iconName);
    }
  }, [avatarIcon]);

  const [desiredRandomness, setDesiredRandomness] = useState(() => {
    const savedRandomness = localStorage.getItem('desiredRandomness');
    // Explicitly check for null to differentiate between "0" and "doesn't exist"
    if (savedRandomness === null) {
      return defaultDesiredRandomness; // Default value when nothing is saved
    }
    const parsedRandomness = parseInt(savedRandomness, /*radix*/10);
    // Use parsed value directly as we've already handled the null case
    return !isNaN(parsedRandomness) ? parsedRandomness : defaultDesiredRandomness;
  });

  useEffect(() => {
    // Save the value as a string; "0" is truthy here so it will be saved correctly
    localStorage.setItem('desiredRandomness', desiredRandomness.toString());
  }, [desiredRandomness]);
  const [excludedPatterns, setExcludedPatterns] = useState([]
    /*() => {
    const savedPatterns = localStorage.getItem('excludedPatterns');
    return savedPatterns ? savedPatterns.split(',') : [];
    }*/
  );

  // useEffect(() => {
  //   localStorage.setItem('excludedPatterns', excludedPatterns.toString());
  // }, [excludedPatterns]);

  const [includedPatterns, setIncludedPatterns] = useState([]
    /* () => {
    const savedPatterns = localStorage.getItem('includedPatterns');
    return savedPatterns ? savedPatterns.split(',') : [];
    }*/
  );

  // useEffect(() => {
  //   localStorage.setItem('includedPatterns', includedPatterns.toString());
  // }, [includedPatterns]);
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('language');
    return savedLanguage || defaultLanguage;
  });

  useEffect(() => {
    localStorage.setItem('language', selectedLanguage);
  }, [selectedLanguage]);


  const [selectedStatisticsLanguage, setSelectedStatisticsLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('statisticsLanguage');
    return savedLanguage || defaultLanguage;
  });

  useEffect(() => {
    localStorage.setItem('statisticsLanguage', selectedStatisticsLanguage);
  }, [selectedStatisticsLanguage]);

  const [selectedWordList, setSelectedWordList] = useState(() => {
    const savedWordList = localStorage.getItem('wordList');
    return wordListSizes.includes(savedWordList) ? savedWordList : defaultWordList;
  });

  useEffect(() => {
    localStorage.setItem('wordList', selectedWordList);
  }, [selectedWordList]);

  const [selectedTestLength, setSelectedTestLength] = useState(() => {
    const savedTestLength = localStorage.getItem('testLength');
    return testLengths.includes(savedTestLength) ? savedTestLength : defaultTestLength;
  });

  const [letterLimit, setLetterLimit] = useState(() => {
    const savedLetterLimit = localStorage.getItem('letterLimit');
    const parsedLetterLimit = parseInt(savedLetterLimit, 10);
    return !isNaN(parsedLetterLimit) && parsedLetterLimit >= 0 ?
      parsedLetterLimit : defaultLetterLimit;
  });

  useEffect(() => {
    localStorage.setItem('letterLimit', letterLimit.toString());
  }, [letterLimit]);

  const [wordRepetition, setWordRepetition] = useState(() => {
    const savedWordRepetition = localStorage.getItem('wordRepetition');
    const parsedWordRepetition = parseInt(savedWordRepetition, 10);
    return !isNaN(parsedWordRepetition) && parsedWordRepetition >= 0 ?
      parsedWordRepetition : defaultWordRepetition;
  });

  useEffect(() => {
    localStorage.setItem('wordRepetition', wordRepetition.toString());
  }, [wordRepetition]);

  const [patternsAccordionExpanded, setPatternsAccordionExpanded] = useState(() => {
    const savedState = localStorage.getItem('patternsAccordionExpanded');
    return savedState === null ? true : savedState === 'true'; // Default to true if nothing is saved
  });

  useEffect(() => {
    localStorage.setItem('patternsAccordionExpanded', patternsAccordionExpanded.toString());
  }, [patternsAccordionExpanded]);


  const [settingsAccordionExpanded, setSettingsAccordionExpanded] = useState(() => {
    const savedState = localStorage.getItem('settingsAccordionExpanded');
    return savedState === null ? true : savedState === 'true'; // Default to true if nothing is saved
  });

  useEffect(() => {
    localStorage.setItem('settingsAccordionExpanded', settingsAccordionExpanded.toString());
  }, [settingsAccordionExpanded]);

  const [statisticsAccordionExpanded, setStatisticsAccordionExpanded] = useState(() => {
    const savedState = localStorage.getItem('statisticsAccordionExpanded');
    return savedState === null ? true : savedState === 'true'; // Default to true if nothing is saved
  });

  useEffect(() => {
    localStorage.setItem('statisticsAccordionExpanded', statisticsAccordionExpanded.toString());
  }, [statisticsAccordionExpanded]);


  const [sessionSummaryAccordionExpanded, setSessionSummaryAccordionExpanded] = useState(() => {
    const savedState = localStorage.getItem('sessionSummaryAccordionExpanded');
    return savedState === null ? true : savedState === 'true'; // Default to true if nothing is saved
  });

  useEffect(() => {
    localStorage.setItem('sessionSummaryAccordionExpanded', sessionSummaryAccordionExpanded.toString());
  }, [sessionSummaryAccordionExpanded]);

  useEffect(() => {
    localStorage.setItem('testLength', selectedTestLength);
  }, [selectedTestLength]);



  const clearHistoryManager = () => {
    testAnalysisRef.current.clearHistory();
  };

  const getRandomTest = async () => {
    console.log("getRandomTest")
    let fetchedText = '';
    setIsLoading(true);
    await new Promise(resolve => setTimeout(resolve, 0));
    testAnalysisRef.current.setUserRequestedPatterns(includedPatterns, excludedPatterns);
    const { wordCount, wordList } = getTestConfiguration(selectedTestLength, selectedWordList, selectedLanguage);
    fetchedText = await testAnalysisRef.current.getNextTest(
      wordCount,
      wordList,
      100,
      true,
      setAlgorithmFoundWords,
      69, //letterLimit,
      punctuation,
      wordRepetition
    );
    setTypingTestText(fetchedText);
    setIsLoading(false);
  };

  // const resetLesson = useCallback(() => {
  //   if (testAnalysisRef.current) {
  //     console.log("Resetting lesson");
  //     testAnalysisRef.current.historyManager = new HistoryManager();
  //     isFullyRandom.current = true;
  //     getRandomTest();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else {
  //     console.log("Basic test parameters changed - resetting lesson");
  //     resetLesson();
  //   }
  // }, [selectedLanguage, selectedWordList, selectedTestLength]);



  // Helper function to safely compare values, treating undefined as equal to any value
  const safeCompare = (current, previous) => {
    // If previous is undefined, treat values as equal (no change)
    if (previous === undefined) return false;
    // Otherwise do normal comparison
    return current !== previous;
  };

  // Main settings comparison function
  const checkSettingsChanged = (current, previous) => {
    // If no previous settings exist at all, return false to avoid regeneration
    if (!previous) return false;

    return (
      safeCompare(current.selectedTestLength, previous.selectedTestLength) ||
      safeCompare(current.selectedWordList, previous.selectedWordList) ||
      safeCompare(current.desiredRandomness, previous.desiredRandomness) ||
      safeCompare(current.letterLimit, previous.letterLimit) ||
      safeCompare(current.selectedLanguage, previous.selectedLanguage) ||
      safeCompare(current.punctuation, previous.punctuation) ||
      safeCompare(current.excludedPatterns, previous.excludedPatterns) || 
      safeCompare(current.randomDuration, previous.randomDuration) ||
      safeCompare(current.wordRepetition, previous.wordRepetition)
    );
  };


  const lastSettingsRef = useRef(null);

  useEffect(() => {
    // Initialize lastSettingsRef with current values on mount
    lastSettingsRef.current = {
      selectedTestLength,
      selectedWordList,
      desiredRandomness,
      letterLimit,
      selectedLanguage,
      punctuation,
      excludedPatterns,
      randomDuration,
      wordRepetition
    };
  }, []); // Empty dependency array means this runs once on mount

  // When the user makes changes, we sometimes want the test to immediatly reflect those changes
  // and sometimes we don't.
  const getNewTestBasedOnUserPatternsChanges = useCallback(async () => {
    console.log("getNewTestBasedOnUserPatternsChanges")
    // If we're in random phase, only regenerate if non-pattern settings changed


    //I moved this stuff here.
    //it was in the "else" clause of the "if (randomPhase.current)
    //for some reason, but really when there are user changes we reset
    //to random phase so we're almost never in "else".
      testAnalysisRef.current.historyManager = new HistoryManager();
      testAnalysisRef.current.testGenerator.returnAllRemovedWordsToPool();
      testAnalysisRef.current.patternsPacker = new PatternsPacker();


    if (randomPhase.current) {
      // Skip test regeneration if only patterns changed
      const settingsChanged = checkSettingsChanged(
        {
          selectedTestLength,
          selectedWordList,
          desiredRandomness,
          letterLimit,
          selectedLanguage,
          punctuation,
          excludedPatterns,
          randomDuration,
          wordRepetition
        },
        lastSettingsRef.current
      );

      console.log("Settings change check - changed:", settingsChanged, {
        testLength: selectedTestLength,
        wordList: selectedWordList,
        randomness: desiredRandomness,
        letterLimit: letterLimit,
        language: selectedLanguage,
        punctuation: punctuation,
        excludedPatterns: excludedPatterns,
        randomDuration: randomDuration,
        wordRepetition: wordRepetition,
        prevTestLength: lastSettingsRef.current?.selectedTestLength,
        prevWordList: lastSettingsRef.current?.selectedWordList,
        prevRandomness: lastSettingsRef.current?.desiredRandomness,
        prevLetterLimit: lastSettingsRef.current?.letterLimit,
        prevLanguage: lastSettingsRef.current?.selectedLanguage,
        prevPunctuation: lastSettingsRef.current?.punctuation,
        prevExcludedPatterns: lastSettingsRef.current?.excludedPatterns,
        prevRandomDuration: lastSettingsRef.current?.randomDuration,
        prevWordRepetition: lastSettingsRef.current?.wordRepetition
      });

      if (!settingsChanged) {
        return;  // Skip everything if no relevant changes
      }
    
      setIsLoading(true);
      await new Promise(resolve => setTimeout(resolve, 0));
      getRandomTest();
    } else {
      setIsLoading(true);
      await new Promise(resolve => setTimeout(resolve, 0));
      testAnalysisRef.current.setUserRequestedPatterns(includedPatterns, excludedPatterns);

      //it was here

      const { wordCount, wordList } = getTestConfiguration(selectedTestLength, selectedWordList, selectedLanguage);
      const fetchedText =
        await testAnalysisRef.current.getNextTest(
          wordCount,
          wordList,
          desiredRandomness,
          true,
          setAlgorithmFoundWords,
          letterLimit,
          punctuation,
          wordRepetition
        );
      setTypingTestText(fetchedText);
    }

    // Store current settings for next comparison
    lastSettingsRef.current = {
      selectedTestLength,
      selectedWordList,
      desiredRandomness,
      letterLimit,
      selectedLanguage,
      punctuation,
      excludedPatterns,
      randomDuration,
      wordRepetition
    };

    setIsLoading(false);
  });

  // Claude wrote this debounced version
  const DEBOUNCE_DELAY = 500; // Static 500ms delay
  const debouncedGetNewTestRef = useRef(null);

  useEffect(() => {
    // Create the debounced function
    debouncedGetNewTestRef.current = debounce(() => {
      console.log("Fetching new test based on changes to requested user patterns");
      getNewTestBasedOnUserPatternsChanges().catch(error => {
        console.error("Error fetching new test based on user patterns changes: ", error);
      });
    }, DEBOUNCE_DELAY);

    // Cleanup function to cancel any pending debounced calls
    return () => {
      if (debouncedGetNewTestRef.current) {
        debouncedGetNewTestRef.current.cancel();
      }
    };
  }, [getNewTestBasedOnUserPatternsChanges]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (debouncedGetNewTestRef.current) {
      debouncedGetNewTestRef.current();
    }
  }, [
    excludedPatterns,
    includedPatterns,
    selectedTestLength,
    selectedWordList,
    desiredRandomness,
    selectedLanguage,
    punctuation,
    randomDuration,
    wordRepetition
  ]);

  //This is how I wrote it.
  // Change the typing test when the user makes a change to the patterns or basic test settings
  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else {
  //     console.log("Fetching new test based on changes to requested user patterns");
  //     const fetchData = async () => {
  //       try {
  //         await getNewTestBasedOnUserPatternsChanges();
  //       } catch (error) {
  //         console.error("Error fetching new test based on user patterns changes: ", error);
  //       }
  //     };
  //     fetchData();
  //   }
  // }, [excludedPatterns, includedPatterns, selectedTestLength, selectedWordList, desiredRandomness]);


  const contextValue = {
    selectedWordList, setSelectedWordList, selectedLanguage, setSelectedLanguage,
    selectedTestLength, setSelectedTestLength, includedPatterns, setIncludedPatterns,
    excludedPatterns, setExcludedPatterns, testAnalysisRef, getNewTestBasedOnUserPatternsChanges,
    typingTestText, setTypingTestText, getRandomTest, desiredRandomness, setDesiredRandomness,
    patternsAccordionExpanded, setPatternsAccordionExpanded, statisticsAccordionExpanded,
    setStatisticsAccordionExpanded, clearHistoryManager, algorithmFoundWords,
    setAlgorithmFoundWords, isFullyRandom, testStatus, setTestStatus, avatarIcon, setAvatarIcon,
    letterLimit, setLetterLimit, isLoading, setIsLoading, selectedStatisticsLanguage,
    setSelectedStatisticsLanguage, /*resetLesson,*/ randomPhase, showNewRecord, setShowNewRecord,
    punctuation, setPunctuation, randomDuration, setRandomDuration, wordRepetition, setWordRepetition,
    testEnded, setTestEnded, sessionSummaryAccordionExpanded, setSessionSummaryAccordionExpanded,
    settingsAccordionExpanded, setSettingsAccordionExpanded, displayMode, setDisplayMode
  };

  return (
    <SuperMainContentContext.Provider value={contextValue}>
      {children}
    </SuperMainContentContext.Provider>
  );
};

import React, { useContext, useState, useMemo } from 'react';
import { Container, Typography, Stack, Box, Switch, FormControlLabel } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { MainContentContext } from '../contexts/MainContentContext';
import { SuperMainContentContext } from '../contexts/SuperMainContext';
import NewRecordCelebration from './NewRecordCelebration';
import SessionSummary from './SessionSummary';

import useTheme from '@mui/material/styles/useTheme';

const AdvancedSimpleToggle = () => {

  const { typingResultsAdvanced, setTypingResultsAdvanced } = useContext(MainContentContext);

  return (
    <ToggleButtonGroup
      value={typingResultsAdvanced}
      exclusive
      onChange={(event, newValue) => setTypingResultsAdvanced(newValue)}
      aria-label="text alignment"
      data-tour="advanced-results-toggle"
    >
      <ToggleButton value={true} aria-label="left aligned">
        Advanced
      </ToggleButton>
      <ToggleButton value={false} aria-label="centered">
        Simple
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

const AdvancedSimpleSwitch = () => {
  const { typingResultsAdvanced, setTypingResultsAdvanced } = useContext(MainContentContext);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={typingResultsAdvanced}
          onChange={(event) => setTypingResultsAdvanced(event.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      }
    />
  );
}


const SimplifiedTestResults = () => {
  const { WPM, accuracy, sessionWPMscores, typingResultsAdvanced } = useContext(MainContentContext);
  const { showNewRecord, setShowNewRecord } = useContext(SuperMainContentContext);


  const stats = useMemo(() => {
    if (sessionWPMscores.length === 0) return { min: 0, max: 0, avg: 0 };
    return {
      min: Math.min(...sessionWPMscores),
      max: Math.max(...sessionWPMscores),
      avg: Math.round(sessionWPMscores.reduce((a, b) => a + b) / sessionWPMscores.length)
    };
  }, [sessionWPMscores]);

  return (
    <>
      <Stack direction="row"
        spacing={3}
        sx={{
          display: 'flex',
          alignItems: 'center',
          m: '1em'
        }}
      >
        <AdvancedSimpleSwitch />
        <Typography >WPM:{WPM}</Typography>
        <Typography >Accuracy:{accuracy}%</Typography>
        <Typography >Avg WPM:{stats.avg}</Typography>
      </Stack>
      <NewRecordCelebration show={showNewRecord} wpm={WPM} onClose={() => setShowNewRecord(false)} />
    </>
  );
}



const TypingTestResults = ({ onToggleSwitch }) => {
  const { WPM, accuracy, typingResultsAdvanced, setTypingResultsAdvanced } = useContext(MainContentContext);
  const { showNewRecord, setShowNewRecord } = useContext(SuperMainContentContext);

  const theme = useTheme();


  return (
    <Container>
      <Box sx={{
        // my: 2,
        bgcolor: theme.palette.background.default,
        borderRadius: 1,
        border: 1,
        borderColor: 'transparent',
      }}
      >
        {typingResultsAdvanced && <SessionSummary />}
        <SimplifiedTestResults />
      </Box>
    </Container>
  );

}

export { TypingTestResults, SimplifiedTestResults, AdvancedSimpleToggle };
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '@mui/material/styles';

const NewRecordCelebration = ({ show, wpm, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (show) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
        onClose();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  const MotionBox = motion(Box);

  return (
    <AnimatePresence>
      {isVisible && (
        <Box
          sx={{
            position: 'fixed',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: theme.zIndex.tooltip + 1,
            pointerEvents: 'none',
            m: 0,
            p: 0
          }}
        >
          <MotionBox
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.5, opacity: 0 }}
            sx={{
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              borderRadius: 2,
              boxShadow: theme.shadows[4],
              color: 'white',
              textAlign: 'center',
              px: 4,
              py: 2
            }}
          >
            <MotionBox
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
                🎉 New Record! 🎉 : {wpm} WPM
              </Typography>
            </MotionBox>
          </MotionBox>
        </Box>
      )}
    </AnimatePresence>
  );
};

export default NewRecordCelebration;
import React, { useState, useRef, useEffect } from 'react';
import { Autocomplete, TextField, Box, IconButton, Chip } from '@mui/material';
import { Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';
import CustomChip from './CustomChip';

const isValidRegex = (pattern) => {
  try {
    new RegExp(pattern);
    return true;
  } catch (e) {
    return false;
  }
};

const MAX_PATTERNS = 32;

const CustomAutoComplete = ({
  optionsArr,
  value,
  label,
  placeholder,
  useFreeSolo,
  usePopupIcon = false,
  useReadOnly = false,
  setValues,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [shouldFocus, setShouldFocus] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (shouldFocus && inputRef.current) {
      const input = inputRef.current.querySelector('input');
      if (input) {
        input.focus();
      }
      setShouldFocus(false);
    }
  }, [shouldFocus]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(false);
        setHelperText('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleEdit = (oldValue, newValue) => {
    if (isValidRegex(newValue)) {
      const newValues = value.map(v => v === oldValue ? newValue : v);
      setValues(newValues);
      setError(false);
      setHelperText('');
    } else {
      setError(true);
      setHelperText('Invalid expression');
    }
  };

  const handleAdd = (newValue) => {
    if (value.length >= MAX_PATTERNS) {
      setError(true);
      setHelperText(`Maximum ${MAX_PATTERNS} patterns allowed`);
      return false;
    }
    
    if (isValidRegex(newValue)) {
      setValues([...value, newValue.trim()]);
      setError(false);
      setHelperText('');
      return true;
    }
    
    setError(true);
    setHelperText('Invalid expression');
    return false;
  };

  const handleBlur = (event) => {
    const currentValue = event.target.value;
    if (currentValue.trim() && !value.includes(currentValue.trim())) {
      if (handleAdd(currentValue)) {
        setInputValue('');
      }
    }
  };

  const handleKeyDown = (event) => {
    if ((event.key === 'Enter' || event.key === ' ') && event.target.value.trim()) {
      const newValue = event.target.value.trim();
      if (!value.includes(newValue) && handleAdd(newValue)) {
        setInputValue('');
      }
      event.preventDefault();
    }
  };

  const handleChange = (event, newValue) => {
    if (Array.isArray(newValue)) {
      if (newValue.length > MAX_PATTERNS) {
        setError(true);
        setHelperText(`Maximum ${MAX_PATTERNS} patterns allowed`);
        return;
      }
      setValues(newValue);
      setError(false);
      setHelperText('');
    }
  };

  return (
    <Autocomplete
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      multiple
      options={optionsArr}
      getOptionLabel={(option) => option}
      value={value}
      freeSolo={useFreeSolo || true}
      readOnly={useReadOnly}
      filterSelectedOptions={true}
      forcePopupIcon={usePopupIcon}
      onChange={handleChange}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <CustomChip
            key={index}
            label={option}
            onDelete={() => {
              const newValue = value.filter((_, i) => i !== index);
              setValues(newValue);
            }}
            onEdit={handleEdit}
            onEditComplete={() => setShouldFocus(true)} 
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          ref={inputRef}
          error={error}
          helperText={helperText}
          variant="outlined"
          label={label}
          placeholder={value.length >= MAX_PATTERNS ? '' : placeholder}
          size="small"
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      )}
    />
  );
};

export default CustomAutoComplete;